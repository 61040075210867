import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputField from '../../../components/formFields/inputField';
import CustomButton from '../../../components/customButton';
import TextAreaField from '../../../components/formFields/textareaField';
import SelectField from '../../../components/formFields/selectField';
import { categoriesList } from '../../../utils/utilities';
import { DRAWER_WIDTH } from 'src/config';

interface CreationFormProps {
  onCloseModal: () => void;
  editData: any;
  onSubmitForm: Function;
  loader: boolean;
  editMode: boolean;
}

export default function CreationForm({ onCloseModal, editData, onSubmitForm, loader, editMode }: CreationFormProps) {
  const validationObject = {
    title: yup.string().required('Title is required'),
    status: yup.string().required('Status is required'),
    category: yup.string().required('Category is required'),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editMode ? editData?.title : '',
      description: editMode ? editData?.matrix_description : '',
      status: editMode ? editData?.matrix_status : 'Not Started',
      category: editMode ? editData?.category : 'Personal',
      assumptions: editMode ? editData?.assumptions : '',
      implications: editMode ? editData?.implications : '',
      final_decision: editMode ? editData?.final_decision : '',
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        matrix_description: values?.description,
        matrix_status: values?.status,
        category: values?.category,
        assumptions: values?.assumptions,
        final_decision: values?.final_decision,
        implications: values?.implications,
      };

      onSubmitForm(payloadObj);
    },
  });

  const processList = [
    { value: 'Not Started' },
    { value: 'In Progress' },
    { value: 'Concluded' },
    { value: 'Canceled' },
  ];

  const eachFieldRow =
    'items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200';

  return (
    <form
      className='flex flex-col bg-white shadow-xl'
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className='overflow-y-scroll'
        style={{
          height: editMode ? 'calc(100vh - 164px)' : 'calc(100vh - 180px)',
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor='title'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Title
            </label>
          </div>
          <div className='sm:col-span-2'>
            <InputField
              type='text'
              id='title'
              {...formik.getFieldProps('title')}
              placeholder='Enter project name'
            />

            {formik.touched.title && formik.errors.title ? (
              <div className='text-red-600 text-xs'>{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Description */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor='description'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Description
            </label>
          </div>
          <div className='sm:col-span-2'>
            <TextAreaField
              id='description'
              {...formik.getFieldProps('description')}
              placeholder='Enter a brief description of your project.'
            />
          </div>
        </div>

        {/* Status */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor='status'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Status
            </label>
          </div>
          <div className='sm:col-span-2'>
            <SelectField
              options={processList}
              id='status'
              {...formik.getFieldProps('status')}
            />

            {formik.touched.status && formik.errors.status ? (
              <div className='text-red-600 text-xs'>{formik.errors.status}</div>
            ) : null}
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor='category'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Category
            </label>
          </div>
          <div className='sm:col-span-2'>
            <SelectField
              options={categoriesList}
              id='category'
              {...formik.getFieldProps('category')}
            />

            {formik.touched.category && formik.errors.category ? (
              <div className='text-red-600 text-xs'>{formik.errors.category}</div>
            ) : null}
          </div>
        </div>

        {editMode ? (
          <>
            {/* Assumptions */}
            <div className={eachFieldRow}>
              <div>
                <label
                  htmlFor='description'
                  className='block text-xs font-medium leading-6 text-customDarkBlue'
                >
                  Assumptions
                </label>
              </div>
              <div className='sm:col-span-2'>
                <TextAreaField
                  id='assumptions'
                  {...formik.getFieldProps('assumptions')}
                  placeholder='Enter a brief assumptions of your project.'
                />
              </div>
            </div>

            {/* Implications */}
            <div className={eachFieldRow}>
              <div>
                <label
                  htmlFor='description'
                  className='block text-xs font-medium leading-6 text-customDarkBlue'
                >
                  Implications
                </label>
              </div>
              <div className='sm:col-span-2'>
                <TextAreaField
                  id='implications'
                  {...formik.getFieldProps('implications')}
                  placeholder='Enter a brief implications of your project.'
                />
              </div>
            </div>

            {/* Final Decision */}
            <div className={eachFieldRow}>
              <div>
                <label
                  htmlFor='description'
                  className='block text-xs font-medium leading-6 text-customDarkBlue'
                >
                  Final Decision
                </label>
              </div>
              <div className='sm:col-span-2'>
                <TextAreaField
                  id='final_decision'
                  {...formik.getFieldProps('final_decision')}
                  placeholder='Enter a brief final decision of your project.'
                />
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      {/* Action buttons */}
      <div
        className='fixed bg-white bottom-0 border-t border-gray-200 px-4 py-5 sm:px-6'
        style={{ width: `${DRAWER_WIDTH - 40}px` }}
      >
        <div className='flex justify-end space-x-3'>
          <button
            type='button'
            className='rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            onClick={onCloseModal}
          >
            Cancel
          </button>
          <CustomButton
            type='submit'
            onClickBtn={() => console.log('Clicking...')}
            text='Save'
            loading={loader}
          />
        </div>
      </div>
    </form>
  );
}
