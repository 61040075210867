import React from 'react';
import Spinner from '../spinner';
import logoIcon from '../../assets/images/logo_icon.png';

interface LoaderPageProps {
    page?: string;
}

const LoaderPage = ({ page }: LoaderPageProps) => {
    return (
        <div className={`flex justify-center items-center h-screen ${page === 'design-detail' ? 'h-[300px]' : ''}`}>
            <div className='text-center'>
                <img
                    className='h-[150px] mx-auto mb-5'
                    src={logoIcon}
                    alt='Solution Pilot'
                />
                <Spinner
                    className='mb-2'
                    svgClassName='mr-2'
                />
                Loading...
            </div>
        </div>
    );
};

export default LoaderPage;
