import {
  AdjustmentsHorizontalIcon,
  BookOpenIcon,
  Cog6ToothIcon,
  CubeIcon,
  DocumentTextIcon,
  ScaleIcon,
  SparklesIcon,
  Squares2X2Icon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { getLastIdFromUrl, getQueryParams } from '../../utils/utilities';
import { getProjectById, updateProjectDocument } from '../../redux/projects/projectsApi';
import { setChangedDocument, setMultiSelectedOption } from '../../redux/projects/projectsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

import ChatGPT from './leftSideBar/chatGPT';
import CustomDrawer from '../../components/customDrawer';
import CustomModal from '../../components/customModal';
// import DecisionMatrix from "../decisionMatrix";
import DecisionMatrixAnalysis from '../decisionMatrixDetails';
import DecisionMatrixDetails from './rightSideBar/decisionMatrixDetails';
import DecisionMatrixList from './leftSideBar/decisionMatrix';
import DecisionsMatrixEmpty from './leftSideBar/emptyPages/decisionsMatrixEmpty';
// import DiagramTabs from "./leftSideBar/diagrams/diagramTabs";
import DiagramActions from './leftSideBar/diagrams/diagramActions';
import Diagrams from './leftSideBar/diagrams';
import DiagramsEmpty from './leftSideBar/emptyPages/diagramsEmpty';
import DocumentHeader from '../../components/documentHeader';
import Documents from './leftSideBar/documents';
import EditProjectForm from './leftSideBar/editProjectForm';
import FlowChart from '../flowchart/flow/App';
import FlowChartGenerateForm from '../flowchart/flowChartGenerateForm';
import MovingComp from '../../components/movingComp';
// import ProsCons from "../prosCons";
import ProsConsAnalysis from '../prosConsDetails';
import ProsConsDetails from './rightSideBar/prosConsDetails';
import ProsConsEmpty from './leftSideBar/emptyPages/prosConsEmpty';
import ProsConsList from './leftSideBar/prosCons';
import SaveProjectAsTemplate from '../templates/SaveProjectAsTemplate';
// import SWOT from "../swot";
import SwotAnalysis from '../swotDetails';
import SwotDetails from './rightSideBar/swotDetails';
import SwotEmpty from './leftSideBar/emptyPages/swotEmpty';
import SwotList from './leftSideBar/swot';
// import TChart from "../T-bar";
import TChartAnalysis from '../T-bar-Details';
import TChartDetails from './rightSideBar/tChartDetails';
import TChartEmpty from './leftSideBar/emptyPages/tChartEmpty';
import TChartList from './leftSideBar/tChart';
import TextEditor from './textEditor';
import { Tooltip } from 'react-tooltip';
import WrapperLoader from '../../components/wrapperLoader';
import { classNames } from '../../utils/utilities';
import { createTemplate } from '../../redux/templates/templatesApi';
import { getDocumentFavoriteComponents } from '../../redux/documentComponents/documentComponentsApi';
import { promptMessage } from '../../redux/chatGPT/chatGPTApi';
import throttle from 'lodash/throttle';
import { updateDiagram } from '../../redux/diagrams/diagramsApi';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useReactFlow } from '@xyflow/react';

const leftOptions: any = [
  { id: 1, name: <DocumentTextIcon className='h-[17px] mx-auto' /> },
  { id: 2, name: <CubeIcon className='h-[17px] mx-auto' /> },
  {
    id: 4,
    name: <ScaleIcon className='h-[17px] mx-auto' />,
  },
  {
    id: 5,
    name: <TableCellsIcon className='h-[17px] mx-auto' />,
  },
  {
    id: 6,
    name: <AdjustmentsHorizontalIcon className='h-[17px] mx-auto' />,
  },
  {
    id: 7,
    name: <Squares2X2Icon className='h-[17px] mx-auto' />,
  },
  {
    id: 8,
    name: <Cog6ToothIcon className='h-[17px] mx-auto' />,
    current: false,
  },
];

export default function ProjectDetailsPage() {
  const [projectByIdLoader, setProjectByIdLoader] = useState(false);
  const [value, setValue] = useState('');
  const [width, setWidth] = useState(400);
  const [rightWidth, setRightWidth] = useState(400);
  const [rightSelectedOption, setRightSelectedOption] = useState(0);

  const [isTextEditorDirty, setIsTextEditorDirty] = useState(false);
  const [documentSaveLoading, setDocumentSaveLoading] = useState(false);
  const [openSaveProjectTemp, setOpenSaveProjectTemp] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [generateFlowChartLoader, setGenerateFlowChartLoader] = useState(false);
  const [generatedAICode, setGeneratedAICode] = useState('');
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [isHideBox, setIsHideBox] = useState(false);
  const [isHideRightBox, setIsHideRightBox] = useState(true);
  const [rightOptionsList, setRightOptionsList] = useState<any>([]);

  const [, setIsDiagramDirty] = useState(false);
  const [diagramSaveLoading, setDiagramSaveLoading] = useState(false);

  const [, setDocumentComponentsList] = useState(null);

  const ckeRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { selectedDocument, projectsByIdRes, multiSelectedOption } = useAppSelector((state) => state.projects);
  const { getDocumentFavoriteComponentsRes } = useAppSelector((state) => state.documentComponents);
  const { selectedDiagram, getDiagramsListRes } = useAppSelector((state) => state.diagrams);
  const { collapseSidebar } = useAppSelector((state) => state.profile);

  const { tBarsList } = useAppSelector((state) => state.tbars);
  const { prosConsList } = useAppSelector((state) => state.prosCons);
  const { decisionMatrixList } = useAppSelector((state) => state.decisionMatrixs);
  const { swotsList } = useAppSelector((state) => state.swot);
  const navigate = useNavigate();

  const { getNodes, getEdges } = useReactFlow();
  const queryStringObj = getQueryParams(window.location.href);

  useEffect(() => {
    checkingDetailTabShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSelectedOption]);

  useEffect(() => {
    if (queryStringObj?.active === 't-chart') {
      if (!tBarsList?.length) {
        setIsHideBox(true);
      }

      if (tBarsList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'pros-cons') {
      if (!prosConsList?.length) {
        setIsHideBox(true);
      }

      if (prosConsList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'decision-matrix') {
      if (!decisionMatrixList?.length) {
        setIsHideBox(true);
      }

      if (decisionMatrixList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'swot') {
      if (!swotsList?.length) {
        setIsHideBox(true);
      }

      if (swotsList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'diagrams') {
      if (!getDiagramsListRes?.length) {
        setIsHideBox(true);
      }

      // if (getDiagramsListRes?.length > 0) {
      //   setIsHideBox(false);
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tBarsList, prosConsList, decisionMatrixList, swotsList, getDiagramsListRes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(getDocumentFavoriteComponents({ accessToken }));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getDocumentFavoriteComponentsRes?.length > 0) {
      const newList = [];

      getDocumentFavoriteComponentsRes?.forEach((data: any) => {
        newList.push({
          title: data?.title,
          data: () => data?.content,
          description: data?.short_description,
        });
      });

      setDocumentComponentsList(getDocumentFavoriteComponentsRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDocumentFavoriteComponentsRes]);

  useEffect(() => {
    setTimeout(() => {
      const buttons: any = document.querySelectorAll('.ck-button');
      const dropdowns: any = document.querySelectorAll('.ck-dropdown');
      const separators = document.querySelectorAll('.ck-toolbar__separator');

      separators.forEach(function (separator, index) {
        separator.classList.add('separator-' + (index + 1));
      });

      /* For Buttons */
      buttons.forEach(function (button: any) {
        const labelText = button.querySelector('.ck-button__label').textContent.trim();

        if (labelText === 'Undo') {
          button.id = 'undo-button';
        } else if (labelText === 'Redo') {
          button.id = 'redo-button';
        } else if (labelText === 'Strikethrough') {
          button.id = 'strikethrough-button';
        } else if (labelText === 'Italic') {
          button.id = 'italic-button';
        } else if (labelText === 'Bold') {
          button.id = 'bold-button';
        } else if (labelText === 'Link') {
          button.id = 'link-button';
        } else if (labelText === 'Underline') {
          button.id = 'underline-button';
        } else if (labelText === 'Subscript') {
          button.id = 'subscript-button';
        } else if (labelText === 'Superscript') {
          button.id = 'superscript-button';
        } else if (labelText === 'Remove Format') {
          button.id = 'remove-format-button';
        } else if (labelText === 'To-do List') {
          button.id = 'to-do-list-button';
        } else if (labelText === 'Multi-level List') {
          button.id = 'multi-level-list-button';
        } else if (labelText === 'Decrease indent') {
          button.id = 'decrease-indent-button';
        } else if (labelText === 'Increase indent') {
          button.id = 'increase-indent-button';
        } else if (labelText === 'Table of contents') {
          button.id = 'table-of-contents-button';
        } else if (labelText === 'Show blocks') {
          button.id = 'show-blocks-button';
        } else if (labelText === 'Block quote') {
          button.id = 'block-quote-button';
        } else if (labelText === 'Code') {
          button.id = 'code-button';
        } else if (labelText === 'Find and replace') {
          button.id = 'find-and-replace-button';
        } else if (labelText === 'Insert HTML') {
          button.id = 'insert-html-button';
        } else if (labelText === 'Horizontal line') {
          button.id = 'horizontal-line-button';
        } else if (labelText === 'Select all') {
          button.id = 'select-all-button';
        } else if (labelText === 'Enable editing') {
          button.id = 'enable-editing-button';
        } else if (labelText === 'Align left') {
          button.id = 'align-left-button';
        } else if (labelText === 'Align right') {
          button.id = 'align-right-button';
        } else if (labelText === 'Align center') {
          button.id = 'align-center-button';
        } else if (labelText === 'Justify') {
          button.id = 'justify-button';
        } else if (labelText === 'Accessibility help') {
          button.id = 'accessibility-help-button';
        }
      });

      /* For Dropdowns */
      dropdowns.forEach(function (drop: any) {
        const labelText = drop.querySelector('.ck-button__label').textContent.trim();

        if (labelText === 'Insert template') {
          drop.id = 'insert-template-dropdown';
        } else if (labelText === 'Insert table') {
          drop.id = 'insert-table-dropdown';
        } else if (labelText === 'Insert media') {
          drop.id = 'insert-media-dropdown';
        } else if (labelText === 'Upload image from computer') {
          drop.id = 'upload-image-dropdown';
        } else if (labelText === 'Upload image from computer') {
          drop.id = 'upload-image-dropdown';
        } else if (labelText === 'Special characters') {
          drop.id = 'special-characters-dropdown';
        } else if (labelText === 'Highlight') {
          drop.id = 'highlight-dropdown';
        }
      });
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedDocument]);

  useEffect(() => {
    const fetchData = async () => {
      setProjectByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProjectById({
              accessToken,
              id: getLastIdFromUrl(window.location.pathname),
            })
          );

        setProjectByIdLoader(false);
      } catch (error) {
        console.error('Error getting access token:', error);
        setProjectByIdLoader(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    setValue(selectedDocument?.content ? selectedDocument?.content : `${selectedDocument?.title}`);
  }, [selectedDocument]);

  useEffect(() => {
    if (projectsByIdRes?.autosave) {
      if (isTextEditorDirty) {
        const timeOutId = setTimeout(() => {
          updatingProjectDocument();
        }, 5000);

        return () => {
          clearTimeout(timeOutId);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTextEditorDirty]);

  useEffect(() => {
    return () => {
      if (isTextEditorDirty) {
        updatingProjectDocument();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calculatingWidth = throttle(() => {
      const mainWrapper = document.getElementById('project_detail_id');
      const contentArea = document.getElementById('project-detail-content');

      if (contentArea && mainWrapper) {
        const contentWidth =
          mainWrapper.clientWidth - ((isHideRightBox ? 0 : rightWidth) + (isHideBox ? 0 : width) + 82);

        contentArea.style.width = `${contentWidth}px`;
      }
    }, 100);

    calculatingWidth();
    window.addEventListener('resize', calculatingWidth);

    return () => {
      window.removeEventListener('resize', calculatingWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, rightWidth, isHideBox, isHideRightBox, collapseSidebar]);

  useEffect(() => {
    const tabId =
      (queryStringObj?.active === 'documents' && 1) ||
      (queryStringObj?.active === 'diagrams' && 2) ||
      (queryStringObj?.active === 't-chart' && 4) ||
      (queryStringObj?.active === 'pros-cons' && 5) ||
      (queryStringObj?.active === 'decision-matrix' && 6) ||
      (queryStringObj?.active === 'swot' && 7) ||
      (queryStringObj?.active === 'settings' && 8);

    if (tabId === 4 || tabId === 5 || tabId === 6 || tabId === 7) {
      setIsHideBox(true);
    }

    dispatch(setMultiSelectedOption(tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkingDetailTabShow = () => {
    const rightOptions: any = [
      {
        id: 1,
        name: <SparklesIcon className='h-[17px] mx-auto' />,
      },
    ];

    if (
      (queryStringObj?.active === 't-chart' && tBarsList?.length > 0) ||
      (queryStringObj?.active === 'pros-cons' && prosConsList?.length > 0) ||
      (queryStringObj?.active === 'decision-matrix' && decisionMatrixList?.length > 0) ||
      (queryStringObj?.active === 'swot' && swotsList?.length > 0)
    ) {
      rightOptions.push({
        id: 2,
        name: <BookOpenIcon className='h-[17px] mx-auto' />,
      });
    } else {
      if (rightSelectedOption === 2) {
        setIsHideRightBox(true);
      }
    }

    setRightOptionsList(rightOptions);
  };

  const updatingProjectDocument = () => {
    const fetchData = async () => {
      try {
        setDocumentSaveLoading(true);
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProjectDocument({
              body: {
                content: value,
              },
              accessToken,
              project_id: selectedDocument?.project_id,
              document_id: selectedDocument?.id,
            })
          ).then(() => {
            setIsTextEditorDirty(false);
          });

          setDocumentSaveLoading(false);
        }
      } catch (error) {
        setDocumentSaveLoading(false);
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const updatingProjectDiagram = () => {
    const fetchData = async () => {
      try {
        setDiagramSaveLoading(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            updateDiagram({
              body: {
                title: selectedDiagram?.title,
                diagram_status: 'Approved',
                design: JSON.stringify({
                  nodes: getNodes(),
                  edges: getEdges(),
                }),
              },
              accessToken,
              project_id: selectedDiagram?.project_id,
              diagram_id: selectedDiagram?.id,
            })
          ).then(() => {
            setIsDiagramDirty(false);
          });

          setDiagramSaveLoading(false);
        }
      } catch (error) {
        setDiagramSaveLoading(false);
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const addItemToTextEditor = (text: string) => {
    const newText = '\n\n```plaintext\n' + text + '\n```';

    if (ckeRef.current) {
      const currentPosition = ckeRef.current.model.document.selection.getFirstPosition();

      // Access the model and then call insertContent
      ckeRef.current.model.change((writer: any) => {
        writer.insert(newText, currentPosition);
      });
    }
  };

  const onChangeMultiOptions = (id: number) => {
    const tabName =
      (id === 1 && 'documents') ||
      (id === 2 && 'diagrams') ||
      (id === 4 && 't-chart') ||
      (id === 5 && 'pros-cons') ||
      (id === 6 && 'decision-matrix') ||
      (id === 7 && 'swot') ||
      (id === 8 && 'settings');

    if (multiSelectedOption !== id) {
      setIsHideBox(false);
    }

    if (multiSelectedOption === id) {
      setIsHideBox(!isHideBox);
    }

    dispatch(setMultiSelectedOption(id));
    navigate(`${window.location.pathname}?active=${tabName}`);
  };

  const onChangeRightOptions = (id: number) => {
    if (rightSelectedOption !== id) {
      setIsHideRightBox(false);
    }

    if (rightSelectedOption === id) {
      setIsHideRightBox(!isHideRightBox);
    }
    setRightSelectedOption(id);
  };

  const onChangedDocumentContent = (documentValue: string) => {
    setValue(documentValue);
    dispatch(setChangedDocument(documentValue));
    setIsTextEditorDirty(true);
  };

  const savingProjectAsTemp = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createTemplate({
              body,
              accessToken,
              id: getLastIdFromUrl(window.location.pathname),
            })
          ).then((res) => {
            if (res) {
              setOpenSaveProjectTemp(false);
              setFormLoader(false);
              // navigate(`/templates`);
            }
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const generatingFlowChartWithAI = (text: string, conversationId: string) => {
    const messagesArray = [
      {
        role: 'user',
        content: text,
      },
    ];
    const payload = {
      messages: messagesArray,
    };

    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setGenerateFlowChartLoader(true);

          await dispatch(
            promptMessage({
              body: payload,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
              conversation_id: conversationId,
            })
          ).then(async (res) => {
            setGeneratedAICode(res?.payload?.data?.completion?.completion_response);

            setGenerateFlowChartLoader(false);
            setOpenGenerateModal(false);
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setGenerateFlowChartLoader(false);
      }
    };

    fetchData();
  };

  return (
    <WrapperLoader loading={projectByIdLoader}>
      <div
        className={`-mb-6 flex mt-[${multiSelectedOption === 2 ? 0 : 89}px]`}
        id='project-container'
      >
        <div className='w-full'>
          <div
            className={`flex w-full`}
            id='project_detail_id'
          >
            <div
              // className="bg-white border border-zinc-100 rounded-xl ml-2 mb-2 shadow-xl z-30"
              // style={{ height: "calc(100vh - 202px)" }}
              className='bg-white border-r border-zinc-200 z-30'
              style={{
                height: `calc(100vh - ${
                  (multiSelectedOption === 2 && 49) ||
                  ((multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) && 49) ||
                  ((multiSelectedOption === 4 ||
                    multiSelectedOption === 5 ||
                    multiSelectedOption === 6 ||
                    multiSelectedOption === 7) &&
                    57)
                }px)`,
              }}
            >
              <nav
                className='-mb-px flex flex-col'
                aria-label='Tabs'
              >
                {leftOptions.map((tab: any) => (
                  <div key={tab.id}>
                    <span
                      data-tooltip-id={`tab-tooltip-${tab?.id}`}
                      onClick={() => onChangeMultiOptions(tab.id)}
                      className={classNames(
                        tab.id === multiSelectedOption
                          ? 'text-amber-500 bg-zinc-50'
                          : 'border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap inline-block border-b-2 p-3 text-xs font-medium cursor-pointer text-center hover:bg-zinc-50 rounded-xl'
                      )}
                      style={{ marginLeft: 0, border: 0 }}
                    >
                      {tab.name}
                    </span>

                    <Tooltip
                      id={`tab-tooltip-${tab?.id}`}
                      place='right'
                    >
                      <div className='line-clamp-1'>
                        {(tab?.id === 1 && 'Documents') ||
                          (tab?.id === 2 && 'Diagrams') ||
                          (tab?.id === 3 && 'Ai Chats') ||
                          (tab?.id === 4 && 'T-Chart') ||
                          (tab?.id === 5 && 'Pros & Cons') ||
                          (tab?.id === 6 && 'Decision Matrix') ||
                          (tab?.id === 7 && 'SWOT Analysis') ||
                          (tab?.id === 8 && 'Settings') ||
                          ''}
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </nav>
            </div>
            <MovingComp
              side='left'
              setWidth={setWidth}
              width={isHideBox ? 0 : width}
            >
              <div
                className='bg-white pl-2 pr-4 z-30'
                style={{
                  height: `calc(100vh - 61px)`,
                }}
              >
                {multiSelectedOption === 1 && (
                  <div className={`mt-3`}>
                    <Documents
                      isTextEditorDirty={isTextEditorDirty}
                      updatingProjectDocument={updatingProjectDocument}
                    />
                  </div>
                )}

                {multiSelectedOption === 2 && (
                  <div className={`mt-3`}>
                    <Diagrams
                      isTextEditorDirty={isTextEditorDirty}
                      updatingProjectDocument={updatingProjectDocument}
                    />
                  </div>
                )}

                {multiSelectedOption === 3 && (
                  <div className={`mt-3`}>
                    <ChatGPT addItemToTextEditor={addItemToTextEditor} />
                  </div>
                )}

                {multiSelectedOption === 4 && (
                  <div className={`mt-3`}>
                    <TChartList checkingDetailTabShow={checkingDetailTabShow} />
                  </div>
                )}

                {multiSelectedOption === 5 && (
                  <div className={`mt-3`}>
                    <ProsConsList checkingDetailTabShow={checkingDetailTabShow} />
                  </div>
                )}

                {multiSelectedOption === 6 && (
                  <div className={`mt-3`}>
                    <DecisionMatrixList checkingDetailTabShow={checkingDetailTabShow} />
                  </div>
                )}

                {multiSelectedOption === 7 && (
                  <div className={`mt-3`}>
                    <SwotList checkingDetailTabShow={checkingDetailTabShow} />
                  </div>
                )}

                {multiSelectedOption === 8 && (
                  <div className={`mt-3`}>
                    <EditProjectForm />
                  </div>
                )}
              </div>
            </MovingComp>

            <div id='project-detail-content'>
              {multiSelectedOption === 2 && (
                <>
                  {getDiagramsListRes?.length > 0 ? (
                    <div className={`relative bg-white`}>
                      <DiagramActions
                        setOpenGenerateModal={() => setOpenGenerateModal(true)}
                        diagramSaveLoading={diagramSaveLoading}
                        updatingProjectDiagram={updatingProjectDiagram}
                      />
                      <FlowChart diagram={generatedAICode} />
                    </div>
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <DiagramsEmpty />
                    </div>
                  )}
                </>
              )}

              {(multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) && (
                <div>
                  {(multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) && (
                    <div className='h-[44px] flex items-center mt-2 mb-[2px] pb-2 relative bottom-[0] border-b border-zinc-200'>
                      <DocumentHeader
                        documentSaveLoading={documentSaveLoading}
                        updatingProjectDocument={updatingProjectDocument}
                        value={value}
                        onOpenSaveProjectTemp={setOpenSaveProjectTemp}
                      />
                    </div>
                  )}
                  <TextEditor
                    value={value}
                    setValue={onChangedDocumentContent}
                    ckeRef={ckeRef}
                    // tempList={documentComponentsList}
                  />
                </div>
              )}

              {multiSelectedOption === 4 && (
                <>
                  {tBarsList?.length > 0 ? (
                    <TChartAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <TChartEmpty />
                    </div>
                  )}
                </>
              )}
              {multiSelectedOption === 5 && (
                <>
                  {prosConsList?.length > 0 ? (
                    <ProsConsAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <ProsConsEmpty />
                    </div>
                  )}
                </>
              )}
              {multiSelectedOption === 6 && (
                <>
                  {decisionMatrixList?.length > 0 ? (
                    <DecisionMatrixAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <DecisionsMatrixEmpty />
                    </div>
                  )}
                </>
              )}
              {multiSelectedOption === 7 && (
                <>
                  {swotsList?.length > 0 ? (
                    <SwotAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <SwotEmpty />
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Right Side Bar */}
            <MovingComp
              side='right'
              setWidth={setRightWidth}
              width={isHideRightBox ? 0 : rightWidth}
            >
              <div
                className='bg-white px-3 z-30 border-l-2 border-zinc-200'
                style={{
                  height: `calc(100vh - 61px)`,
                }}
              >
                <div className={`mt-3 ${rightSelectedOption === 1 ? 'block' : 'hidden'}`}>
                  <ChatGPT addItemToTextEditor={addItemToTextEditor} />
                </div>

                {rightSelectedOption === 2 && (
                  <div className='mt-3'>
                    {queryStringObj?.active === 't-chart' && <TChartDetails />}

                    {queryStringObj?.active === 'pros-cons' && <ProsConsDetails />}

                    {queryStringObj?.active === 'decision-matrix' && <DecisionMatrixDetails />}

                    {queryStringObj?.active === 'swot' && <SwotDetails />}
                  </div>
                )}
              </div>
            </MovingComp>

            <div
              // className="bg-white border border-zinc-100 rounded-xl ml-2 mb-2 shadow-xl z-30"
              // style={{ height: "calc(100vh - 202px)" }}
              className='bg-white border-l border-zinc-200 z-30'
              style={{
                height: `calc(100vh - ${
                  (multiSelectedOption === 2 && 49) ||
                  ((multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) && 49) ||
                  ((multiSelectedOption === 4 ||
                    multiSelectedOption === 5 ||
                    multiSelectedOption === 6 ||
                    multiSelectedOption === 7) &&
                    57)
                }px)`,
              }}
            >
              <nav
                className='-mb-px flex flex-col'
                aria-label='Tabs'
              >
                {rightOptionsList?.length > 0 &&
                  rightOptionsList.map((tab: any) => (
                    <div key={tab.id}>
                      <span
                        data-tooltip-id={`right-tab-tooltip-${tab?.id}`}
                        onClick={() => onChangeRightOptions(tab.id)}
                        className={classNames(
                          tab.id === rightSelectedOption
                            ? 'text-amber-500 bg-zinc-50'
                            : 'border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap inline-block border-b-2 p-3 text-xs font-medium cursor-pointer text-center hover:bg-zinc-50 rounded-xl'
                        )}
                        style={{ marginLeft: 0, border: 0 }}
                      >
                        {tab.name}
                      </span>

                      <Tooltip
                        id={`right-tab-tooltip-${tab?.id}`}
                        place='right'
                      >
                        <div className='line-clamp-1'>
                          {(tab?.id === 1 && 'Ai Chats') || (tab?.id === 2 && 'Details')}
                        </div>
                      </Tooltip>
                    </div>
                  ))}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <CustomDrawer
        open={openSaveProjectTemp}
        onCloseModal={() => setOpenSaveProjectTemp(false)}
        title='Save as Template'
        description='Save project as template'
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setOpenSaveProjectTemp(false)}
          onSubmitForm={savingProjectAsTemp}
          loader={formLoader}
          projectData={projectsByIdRes}
        />
      </CustomDrawer>

      <CustomModal
        open={openGenerateModal}
        onCloseModal={() => setOpenGenerateModal(false)}
        title='Generate Flow Chart'
        size='max-w-lg'
      >
        <FlowChartGenerateForm
          onCloseModal={() => setOpenGenerateModal(false)}
          loading={generateFlowChartLoader}
          onSubmitData={generatingFlowChartWithAI}
        />
      </CustomModal>
    </WrapperLoader>
  );
}
