import React, { DragEvent, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import {
 ArrowRightIcon
} from "@heroicons/react/24/outline";
interface NodeListBoxProps {
  itemIndex: number;
  iconDataList: any;
  onDragStart: Function;
}

const NodeListBox = ({
  itemIndex,
  iconDataList,
  onDragStart,
}: NodeListBoxProps) => {
  const [isCollapse, setIsCollapse] = useState(true);

  useEffect(() => {
    if (itemIndex === 0) {
      setIsCollapse(false);
    }
  }, [itemIndex]);

  return (
    <div className="py-4 border-b border-zinc-200">
      <h3
        className="flex items-center text-zinc-500 font-semibold text-xs cursor-pointer ml-2 w-full"
        onClick={() => setIsCollapse(!isCollapse)}
      >
        <ArrowRightIcon
          className={`h-4 mr-2 rotate-[${isCollapse ? "0deg" : "90deg"}] transition-all`}
        />
        <span>{iconDataList.name}</span>
      </h3>
      <div
        className={`${isCollapse ? "opacity-0 h-0 overflow-hidden" : "opacity-100 h-[100%] mt-2"} flex items-center justify-start flex-wrap transition-all`}
      >
        {iconDataList.data.length > 0 &&
          iconDataList.data.map((item: any) => (
            <div
              key={item.id}
              className="cursor-move"
              onDragStart={(event: DragEvent) =>
                onDragStart(event, "node", item.icon, item.name)
              }
              draggable
            >
              <div
                data-tooltip-id={`node-tooltip-${item.id}`}
              >
                <div className="m-[5px]">
                  <img src={item.icon} alt="iconsList" className="h-[26px]" />
                </div>
              </div>

              <Tooltip
                className="z-[50]"
                id={`node-tooltip-${item.id}`}
                place="top"
              >
                {item.name}
              </Tooltip>
            </div>
          ))}
      </div>
    </div>
  );
};

export default NodeListBox;
