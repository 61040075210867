import React, { useEffect, HTMLAttributes, ReactNode } from 'react';
import { classNames } from 'src/utils/utilities';

import controlIcon from 'src/assets/icons/control.svg';
import enterIcon from 'src/assets/icons/enter.svg';

type KeyType = string | { key: string; ui: ReactNode };

interface KeyboardShortcutProps extends HTMLAttributes<HTMLDivElement> {
  keys: KeyType[];
  onTrigger: () => void;
}

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({ keys, onTrigger, className = '', ...props }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const pressedKeys = keys.map((key) => (typeof key === 'string' ? key : key.key));
      const isShortcutTriggered = pressedKeys.every((key) => {
        return event.getModifierState(key) || event.key === key;
      });

      if (isShortcutTriggered) {
        event.preventDefault();
        onTrigger();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [keys, onTrigger]);

  return (
    <div
      className={classNames('flex text-gray-100 bg-white/50 rounded-[4px]', className)}
      {...props}
    >
      {keys.map((key, index) => (
        <kbd
          key={index}
          className={classNames(
            'py-1 px-0.5 text-[0.625rem]/[1] font-semibold text-gray-800',
            'flex items-center justify-center'
          )}
        >
          {typeof key === 'string' ? key : key.ui}
        </kbd>
      ))}
    </div>
  );
};

const ControlEnter: React.FC<Omit<KeyboardShortcutProps, 'keys'>> = ({ className, ...props }) => {
  return (
    <KeyboardShortcut
      {...props}
      className={classNames(className)}
      keys={[
        {
          key: 'Control',
          ui: (
            <img
              src={controlIcon}
              className='size-4'
              alt='Ctrl'
              aria-hidden
            />
          ),
        },
        {
          key: 'Enter',
          ui: (
            <img
              src={enterIcon}
              className='size-4'
              alt='Enter'
              aria-hidden
            />
          ),
        },
      ]}
    />
  );
};

export default Object.assign(KeyboardShortcut, { ControlEnter });
