import {
  ShieldExclamationIcon,
  GlobeAltIcon,
  CommandLineIcon,
  CloudIcon,
  CubeIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { value: "All", icon: CloudIcon, count: "20" },
  { value: "Cloud Architecture", icon: CloudIcon, count: "5" },
  {
    value: "Software Architecture",
    icon: CommandLineIcon,
    count: "12",
  },
  {
    value: "Containerization",
    icon: CubeIcon,
    count: "20+",
  },
  { value: "DevOps & CI/CD", icon: CubeIcon },
  { value: "Enterprise Business Design", icon: GlobeAltIcon },
  { value: "Security Model", icon: ShieldExclamationIcon },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface CategoriesLeftSidebarProps {
  setSelectedCategory: Function;
  selectedCategory: any;
}

export function CategoriesLeftSidebar({
  setSelectedCategory,
  selectedCategory,
}: CategoriesLeftSidebarProps) {
  return (
    <div className="mr-5 w-full">
      <nav aria-label="Sidebar" className="flex flex-1 flex-col">
        <ul className="-mx-2 space-y-1">
          {navigation.map((item) => (
            <li
              className="cursor-pointer"
              key={item.value}
              onClick={() => setSelectedCategory(item)}
            >
              <span
                className={classNames(
                  selectedCategory?.value === item.value
                    ? "bg-gray-50 text-amber-600"
                    : "text-gray-600 hover:bg-gray-50 hover:text-amber-600",
                  "group flex gap-x-3 rounded-md p-2 text-xs font-semibold leading-6"
                )}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    selectedCategory?.value === item.value
                      ? "text-amber-600"
                      : "text-gray-400 group-hover:text-amber-600",
                    "h-5 w-5 shrink-0"
                  )}
                />
                {item.value}
                {item.count ? (
                  <span
                    aria-hidden="true"
                    className="ml-auto w-7 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                  >
                    {item.count}
                  </span>
                ) : null}
              </span>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
