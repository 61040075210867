import {
    ArrowLeftStartOnRectangleIcon,
    ArrowRightEndOnRectangleIcon,
    BeakerIcon,
    BuildingLibraryIcon,
    ChatBubbleLeftRightIcon,
    DocumentPlusIcon,
    HomeIcon,
    InformationCircleIcon,
    LightBulbIcon,
    Square3Stack3DIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import MenuItemsList from './menuItemsList';
import Tooltip from '../tooltipWrapper';
import mainLogo from '../../assets/images/solution_pilot.svg';
import { setCurrentPage } from '../../redux/profile/profileSlice';
import { useAppDispatch } from '../../hooks';

const navigationsList = [
    {
        id: 1,
        title: '',
        menuList: [
            { id: 1, name: 'Home', href: '/home', icon: HomeIcon, current: true },
            {
                id: 2,
                name: 'Community',
                href: '/community-templates',
                icon: BeakerIcon,
                current: false,
                childrens: [
                    {
                        id: 21,
                        name: 'Designs & Documents',
                        href: '/community-templates',
                        icon: LightBulbIcon,
                        current: false,
                    },
                    {
                        id: 21,
                        name: 'Ai Prompts',
                        href: '/community-ai-assistants',
                        icon: ChatBubbleLeftRightIcon,
                        current: false,
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        title: 'Projects',
        menuList: [
            {
                id: 3,
                name: 'Designs',
                href: '/projects',
                icon: LightBulbIcon,
                current: false,
            },
            {
                id: 4,
                name: 'Content Templates',
                href: '/templates',
                icon: Square3Stack3DIcon,
                current: false,
            },
            {
                id: 499,
                name: 'Document Components',
                href: '/document-components',
                icon: DocumentPlusIcon,
                current: false,
            },
            {
                id: 5,
                name: 'Ai Prompts',
                href: '/ai-assistants',
                icon: ChatBubbleLeftRightIcon,
                current: false,
                childrens: [
                    {
                        id: 51,
                        name: 'Prompt Book',
                        href: '/ai-assistants',
                        icon: ChatBubbleLeftRightIcon,
                        current: false,
                    },
                    {
                        id: 52,
                        name: 'Playground',
                        href: '/playground',
                        icon: ChatBubbleLeftRightIcon,
                        current: false,
                    },
                ],
            },
        ],
    },
];

interface SidebarProps {
    setSidebarOpen: Function;
    classNames: Function;
    sidebarOpen: boolean;
    setCollapseSidebar: Function;
    collapseSidebar: boolean;
}

export default function Sidebar({
    setSidebarOpen,
    classNames,
    sidebarOpen,
    setCollapseSidebar,
    collapseSidebar,
}: SidebarProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { pathname } = useLocation();

    useEffect(() => {
        const basePathname = `/${pathname.split('/')[1]}`;

        group: for (const menuGroup of navigationsList) {
            for (const menuItem of menuGroup.menuList) {
                if (menuItem.href === basePathname) {
                    dispatch(setCurrentPage(menuItem));

                    break group;
                }

                if (menuItem.childrens) {
                    for (const child of menuItem.childrens) {
                        if (child.href === basePathname) {
                            dispatch(setCurrentPage(child));

                            break group;
                        }
                    }
                }
            }
        }
    }, [pathname, dispatch]);

    function openInNewTab(url: string) {
        window.open(url, '_blank');
    }

    const navigationsActions = [
        {
            id: 1,
            name: 'Training',
            href: `${process.env.REACT_APP_BASE_API_URL}/training`,
            icon: BuildingLibraryIcon,
        },
        {
            id: 2,
            name: 'Documentation',
            href: `${process.env.REACT_APP_BASE_API_URL}/documentation`,
            icon: InformationCircleIcon,
        },
        {
            id: 3,
            name: collapseSidebar ? 'Open' : 'Collapse',
            href: '',
            icon: collapseSidebar ? ArrowRightEndOnRectangleIcon : ArrowLeftStartOnRectangleIcon,
        },
    ];

    return (
        <div
            id='main_side_bar'
            className='mt-[48px]'
        >
            <Transition
                show={sidebarOpen}
                as={Fragment}
            >
                <Dialog
                    as='div'
                    className='relative z-50 lg:hidden'
                    onClose={() => setSidebarOpen(false)}
                >
                    <TransitionChild
                        as={Fragment}
                        enter='transition-opacity ease-linear duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='transition-opacity ease-linear duration-300'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-900/80' />
                    </TransitionChild>

                    <div className='fixed inset-0 flex'>
                        <TransitionChild
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='-translate-x-full'
                            enterTo='translate-x-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-x-0'
                            leaveTo='-translate-x-full'
                        >
                            <DialogPanel className='relative mr-16 flex w-full max-w-xs flex-1'>
                                <TransitionChild
                                    as={Fragment}
                                    enter='ease-in-out duration-300'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='ease-in-out duration-300'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                                        <button
                                            type='button'
                                            className='-m-2.5 p-2.5'
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className='sr-only'>Close sidebar</span>
                                            <XMarkIcon
                                                className='h-6 w-6 text-white'
                                                aria-hidden='true'
                                            />
                                        </button>
                                    </div>
                                </TransitionChild>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className='flex grow flex-col gap-y-5 px-6 pb-4 ring-1 ring-white/10'>
                                    <div className='flex h-10 shrink-0 items-center'>
                                        <img
                                            className='h-4 w-auto cursor-pointer'
                                            src={mainLogo}
                                            alt='Solution Pilot'
                                            onClick={() => navigate('/')}
                                        />
                                    </div>
                                    <nav className='flex flex-1 flex-col'>
                                        <ul className='flex flex-1 flex-col gap-y-4 pl-0'>
                                            {navigationsList.map(
                                                (item) => (
                                                    <MenuItemsList
                                                        key={item.id}
                                                        title={item.title}
                                                        classNames={classNames}
                                                        navigationsList={item.menuList}
                                                        collapseSidebar={false}
                                                    />
                                                )
                                            )}
                                        </ul>
                                    </nav>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </Dialog>
            </Transition>

            <div
                className={`group relative lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col transition-all ${
                    collapseSidebar ? 'w-[52px]' : 'lg:w-64'
                }`}
                style={{ top: '55px' }}
            >
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div
                    className={`flex grow flex-col gap-y-5 ${
                        collapseSidebar ? 'px-2' : 'px-6 overflow-y-auto'
                    } pb-36 pt-3`}
                >
                    <nav className={`flex flex-1 flex-col relative`}>
                        <ul className='flex flex-1 flex-col gap-y-3 pl-0'>
                            {navigationsList.map(
                                (item) => (
                                    <MenuItemsList
                                        key={item.id}
                                        title={item.title}
                                        classNames={classNames}
                                        navigationsList={item.menuList}
                                        collapseSidebar={collapseSidebar}
                                    />
                                )
                            )}
                        </ul>
                    </nav>
                </div>

                <div
                    className={`${
                        collapseSidebar ? 'py-4 w-[51px]' : 'p-4'
                    } absolute z-10 bottom-0 transition-all bg-[#fbfbfb] w-[calc(100%_-6px)]`}
                >
                    <ul className='px-2'>
                        {navigationsActions.map((actionBtn) => {
                            return (
                                <li
                                    key={actionBtn.name}
                                    className={`${collapseSidebar ? 'ml-0' : '-ml-[14px]'}`}
                                >
                                    <Tooltip
                                        text={
                                            collapseSidebar ? (
                                                <span className='whitespace-nowrap'>{actionBtn.name}</span>
                                            ) : (
                                                ''
                                            )
                                        }
                                        position='right'
                                    >
                                        <span
                                            onClick={() => {
                                                actionBtn?.href
                                                    ? openInNewTab(actionBtn?.href)
                                                    : dispatch(setCollapseSidebar(!collapseSidebar));
                                            }}
                                            className={`flex gap-x-3 text-zinc-900 opacity-90 hover:opacity-100 hover:bg-zinc-100 hover:text-amber-500 rounded-md p-2 text-xs leading-5 font-medium cursor-pointer items-center ${
                                                collapseSidebar ? 'items-center justify-center' : ''
                                            }`}
                                        >
                                            <span className='text-zinc-900 hover:text-amber-500'>
                                                <actionBtn.icon className='w-[17px] h-[17px]' />
                                            </span>

                                            {!collapseSidebar && <span>{actionBtn.name}</span>}
                                        </span>
                                    </Tooltip>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            {/* <div
        id="collapse_button"
        className={`fixed bottom-14 z-50 transition-all ${
          collapseSidebar ? "left-9" : "left-60"
        } cursor-pointer bg-white w-8 h-8 border border-amber-200 text-amber-500 rounded-full flex items-center justify-center shadow-lg hover:text-white hover:bg-amber-400`}
        onClick={() => dispatch(setCollapseSidebar(!collapseSidebar))}
      >
        <ArrowLeftIcon
          className={`w-4 h-4 transition-all ${
            collapseSidebar ? "rotate-180" : "rotate-0"
          }`}
        />
      </div> */}
        </div>
    );
}
