import { Children, ReactElement, ComponentPropsWithoutRef, cloneElement } from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { classNames } from 'src/utils/utilities';
import { DROPDOWN_TRANSITION_CLASSES } from 'src/config';
import Spinner from '../spinner';

interface OptionProps {
  value: string;
  title: string;
  description: string;
  selected: boolean;
}

interface ButtonProps extends ComponentPropsWithoutRef<'div'> {
  selected: Readonly<OptionProps>;
  label?: string;
  icon?: ReactElement;
  loading?: boolean;
  highlighted?: boolean;
}

interface BrandedSelectProps extends Omit<ButtonProps, 'onChange' | 'selected'> {
  onOptionChange: (option: OptionProps) => void;
  children: ReactElement<OptionProps>[];
}

function Button({
  selected: { title },
  label,
  className,
  icon,
  loading = false,
  highlighted = false,
  ...props
}: Readonly<ButtonProps>) {
  return (
    <div
      className={classNames('inline-flex rounded-md shadow-sm', highlighted && 'divide-x divide-indigo-700', className)}
      {...props}
    >
      <div
        className={classNames(
          'inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2',
          highlighted ? 'bg-indigo-600 text-white shadow-sm' : 'border border-r-0 border-zinc-300 text-customLightBlue'
        )}
      >
        {(() => {
          const className = '-ml-0.5 size-5';

          if (loading)
            return (
              <Spinner
                className={classNames(className, 'flex overflow-hidden')}
                svgClassName='!size-5'
              />
            );

          if (icon)
            return cloneElement(icon, {
              ...icon.props,
              'aria-hidden': true,
              'className': classNames(className, icon.props.className),
            });

          return (
            <CheckIcon
              aria-hidden='true'
              className={className}
            />
          );
        })()}
        <p className='text-sm font-semibold'>{title}</p>
      </div>
      <ListboxButton
        className={classNames(
          'p-2 inline-flex items-center rounded-l-none rounded-r-md transition-colors',
          highlighted
            ? 'bg-indigo-600 hover:bg-indigo-700'
            : 'border border-zinc-300 hover:hover:bg-indigo-700 hover:bg-indigo-700 hover:text-white',
          'focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50',
          'disabled:cursor-not-allowed'
        )}
        disabled={loading}
      >
        <span className='sr-only'>{label || 'Change selection'}</span>
        <ChevronDownIcon
          aria-hidden='true'
          className={classNames('h-5 w-5', highlighted && 'text-white')}
        />
      </ListboxButton>
    </div>
  );
}

function Option({ value, title, description, selected }: Readonly<OptionProps>) {
  return (
    <ListboxOption
      value={value}
      className={classNames(
        'group cursor-default select-none p-4 text-sm',
        'data-[focus]:bg-indigo-600 data-[focus]:text-white text-gray-900'
      )}
    >
      {({ focus }) => (
        <div className='flex flex-col'>
          <div className='flex items-center justify-between'>
            <p className={classNames('font-normal', selected && 'font-semibold')}>{title}</p>
            {selected && (
              <span className={classNames(focus ? 'text-white' : 'text-indigo-600')}>
                <CheckIcon
                  aria-hidden='true'
                  className='h-5 w-5'
                />
              </span>
            )}
          </div>
          <p className={classNames('mt-2 text-left', focus ? 'text-indigo-200' : 'text-gray-500')}>{description}</p>
        </div>
      )}
    </ListboxOption>
  );
}

function BrandedSelect({ onOptionChange, label, className, children, ...buttonProps }: Readonly<BrandedSelectProps>) {
  const options = Children.map(children, ({ props: { value, title, description, selected } }) => ({
    value,
    title,
    description,
    selected,
  }));

  const selectedOption = options.find(({ selected }) => selected) || options[0];

  const handleOptionChange = (optionValue: string) => {
    onOptionChange(options.find(({ value }) => value === optionValue) as OptionProps);
  };

  return (
    <Listbox
      value={selectedOption.value}
      onChange={handleOptionChange}
    >
      {({ open }) => (
        <>
          {label && <Label className='sr-only'>{label}</Label>}
          <div className={classNames('relative', className)}>
            <Button
              selected={selectedOption}
              label={label}
              {...buttonProps}
            />
            <Transition
              show={open}
              as={Fragment}
              {...DROPDOWN_TRANSITION_CLASSES}
            >
              <ListboxOptions
                className={classNames(
                  'absolute left-0 z-10 mt-2 w-72 rounded-md bg-white text-base',
                  'shadow-lg ring-1 ring-black ring-opacity-5',
                  'focus:outline-none sm:text-sm overflow-hidden'
                )}
              >
                {children}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default Object.assign(BrandedSelect, {
  Option,
});
