import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowDownCircleIcon,
  PencilIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
  LightBulbIcon,
DocumentIcon} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from '../../hooks';
import WrapperLoader from '../../components/wrapperLoader';
import SearchField from '../../components/searchField';

import TemplateListBox from '../../components/templateBox/templateListBox';
import TemplateDetailPage from '../../components/templateDetailPage';
import {
  getTemplatesList,
  deleteTemplate,
  getTemplateById,
  createProjectFromTemplate,
  getPublicTemplatesList,
  getPublicTemplateById,
  publishProjectTemplate,
  unPublishProjectTemplate,
} from '../../redux/templates/templatesApi';
import CustomButton from '../../components/customButton';
import CustomModal from '../../components/customModal';
import ProjectsSelection from './ProjectsSelectionForm';
import { createConversation } from '../../redux/chatGPT/chatGPTApi';
import CompleteEmptyPage from 'src/components/completeEmptyPage';
import CommunityleftMenu from '../communityPage/CommunityleftMenu';
import { createProject } from '../../redux/projects/projectsApi';
import CreateProjectForm from '../projects/CreationForm';
import ProjectAsTemplateForm from './ProjectAsTemplateForm';
import CustomDrawer from '../../components/customDrawer';
import { documentsList } from '../../utils/appdata';
import ConfirmationModal from '../../components/confirmationModal';
import PublishConfirmation from '../../components/publishConfirmation';
import DocumentEdit from './DocumentEdit';
import SaveProjectAsTemplate from './SaveProjectAsTemplate';
import { CategoriesLeftSidebar } from 'src/components/categoriesLeftSidebar';

import designTemplateSVG from '../../assets/images/Illustration-08.svg';

const filtersList = [
  { id: 1, title: "Project", icon: <LightBulbIcon className="h-4" /> },
  { id: 2, title: "Single Document", icon: <DocumentIcon className="h-4" /> },
];

interface TemplatesDataProps {
  isModal: boolean;
  onUseWithModal?: Function;
  tempType: string;
  selectedCategory?: any;
}

interface LeftSideProps {
  selectedMainCategory: { value: string };
  setSelectedMainCategory: Function;
  checkTemplatesList?: unknown[];
  tempType: string;
}

function LeftSide({
  selectedMainCategory,
  setSelectedMainCategory,
  checkTemplatesList,
  tempType,
}: Readonly<LeftSideProps>) {
  if (tempType !== 'community') {
    // TODO: Remove this block once categories (CommunityleftMenu) are ready
    return null;
  }

  return (
    <div>
      {selectedMainCategory.value === 'All' && (checkTemplatesList?.length === 0 || !checkTemplatesList) ? (
        ''
      ) : (
        <div className='w-[270px]'>
          {tempType === 'community' ? (
            <div className='bg-white p-6 border-r border-gray-200 h-[calc(100vh_-_49px)]'>
              <CategoriesLeftSidebar
                setSelectedCategory={setSelectedMainCategory}
                selectedCategory={selectedMainCategory}
              />
            </div>
          ) : (
            <CommunityleftMenu />
          )}
        </div>
      )}
    </div>
  );
}

const TemplatesData = ({ isModal, onUseWithModal, tempType, selectedCategory }: TemplatesDataProps) => {
  const [templatesCompleteList, setTemplatesCompleteList] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState('');
  const [formLoader, setFormLoader] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [openSaveAsTemp, setOpenSaveAsTemp] = useState(false);
  const [saveAsTempLoader, setSaveAsTempLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [openDocumentForm, setOpenDocumentForm] = useState(false);
  const [tempDetailLoader, setTempDetailLoader] = useState(false);
  const [openCreateProjectFromTemp, setOpenCreateProjectFromTemp] = useState(false);
  const [publishTempModal, setPublishTempModal] = useState(false);
  const [selectedMainCategory, setSelectedMainCategory] = useState({
    value: 'All',
  });
  const [checkTemplatesList, setCheckTemplatesList] = useState<any>([]);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { templatesList, getPublicTemplatesListRes } = useAppSelector((state) => state.templates);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);
          if (tempType === 'my') await dispatch(getTemplatesList(accessToken));
          if (tempType === 'community')
            await dispatch(
              getPublicTemplatesList({
                accessToken,
                query: selectedCategory
                  ? `?category=${selectedCategory?.value === 'All' ? '' : selectedCategory?.value}`
                  : `?category=${selectedMainCategory?.value === 'All' ? '' : selectedMainCategory?.value}`,
              })
            );

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedMainCategory]);

  useEffect(() => {
    if (tempType === 'my') {
      setTemplatesCompleteList(templatesList);
      setCheckTemplatesList(templatesList);
    }
    if (tempType === 'community') {
      setTemplatesCompleteList(getPublicTemplatesListRes);
      setCheckTemplatesList(getPublicTemplatesListRes);
    }
  }, [templatesList, getPublicTemplatesListRes, tempType]);

  const onSearchingList = (value: string) => {
    setSearch(value);

    if (value) {
      let filteredResults = null;

      if (tempType === 'my') {
        filteredResults = templatesList?.filter((item: any) => item?.title.toLowerCase().includes(value.toLowerCase()));
      }
      if (tempType === 'community') {
        filteredResults = getPublicTemplatesListRes?.filter((item: any) =>
          item?.title.toLowerCase().includes(value.toLowerCase())
        );
      }

      setTemplatesCompleteList(filteredResults);
    } else {
      if (tempType === 'my') setTemplatesCompleteList(templatesList);
      if (tempType === 'community') setTemplatesCompleteList(getPublicTemplatesListRes);
    }
  };

  const onClosingProjectSelection = () => {
    setSelectedAIChatId('');
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`);
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              // navigate(`/projects`);
            }
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const savingDocumentAsTemplateData = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSaveAsTempLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`);
              }
              setOpenSaveAsTemp(false);
              setSaveAsTempLoader(false);
            })
            .catch(() => {
              setSaveAsTempLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const deletingProjectTemplate = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(deleteTemplate({ accessToken, resource_id: selectedId }))
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              setShowTempDetail(null);
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const gettingProjectTempDetail = () => {
    const fetchData = async () => {
      setTempDetailLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (tempType === 'my') {
            await dispatch(getTemplateById({ accessToken, id: showTempDetail?.id }));
          }

          if (tempType === 'community') {
            await dispatch(getPublicTemplateById({ accessToken, id: showTempDetail?.id }));
          }

          setTempDetailLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setTempDetailLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const publishingTemplate = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          const payload = {
            title: showTempDetail?.title,
            status: showTempDetail?.status,
            category: showTempDetail?.category,
            complexity: showTempDetail?.complexity,
            description: showTempDetail?.description,
          };

          await dispatch(
            publishProjectTemplate({
              accessToken,
              id: showTempDetail?.id,
              body: payload,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const creatingProjectFromTemp = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProjectFromTemplate({
              body,
              accessToken,
              id: showTempDetail?.id,
            })
          ).then((res) => {
            if (res) {
              setOpenCreateProjectFromTemp(false);
              setFormLoader(false);
              // navigate(`/templates`);
            }
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onUsingTemplate = (data: any) => {
    setShowTempDetail(data);
    setOpenCreateProjectFromTemp(true);
  };

  const publishingProjectTemplate = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      setPublishingLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            publishProjectTemplate({
              body,
              accessToken,
              id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishTempModal(false);
              }
              setFormLoader(false);
              setPublishingLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const unPublishingTemplate = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishProjectTemplate({
              accessToken,
              id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  return (
    <>
      <div className="flex">
        {!isModal && (
          <LeftSide
            tempType={tempType}
            checkTemplatesList={checkTemplatesList}
            selectedMainCategory={selectedMainCategory}
            setSelectedMainCategory={setSelectedMainCategory}
          />
        )}
        <div
          className="px-4"
          style={{
            width: "100%",
          }}
        >
          {!showTempDetail && (
            <div className="py-4 mb-2 border-b border-zinc-100">
              {" "}
              {selectedMainCategory.value === "All" &&
              (checkTemplatesList?.length === 0 || !checkTemplatesList) ? (
                ""
              ) : (
                <div className="flex items-center justify-between">
                  <div className="w-80">
                    <SearchField
                      onSearchField={onSearchingList}
                      searchValue={search}
                    />
                  </div>

                  <select className="block capitalize rounded-md border-0 py-1 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-50 sm:max-w-xs sm:text-xs sm:leading-6">
                    {filtersList.map((data: any, index: number) => (
                      <option
                        key={index}
                        value={data.title}
                        className="capitalize"
                      >
                        <span className="mr-1">{data.icon}</span>
                        <span>{data.title}</span>
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          )}

          {showTempDetail && checkTemplatesList?.length > 0 && (
            <div className="mb-4 pb-4 border-b border-gray-200 text-right">
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-between w-full">
                  <>
                    <div className="flex">
                      <CustomButton
                        text="Delete"
                        type="button"
                        onClickBtn={() => deletingModalOpen(showTempDetail?.id)}
                        beforeIcon={<TrashIcon className="h-4 mr-1" />}
                        loading={false}
                        outlined
                        btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                      />

                      <CustomButton
                        text={
                          showTempDetail?.privacy === true
                            ? "Publish"
                            : "Unpublish"
                        }
                        type="button"
                        onClickBtn={() => {
                          showTempDetail?.privacy === true
                            ? setPublishTempModal(true)
                            : unPublishingTemplate();
                        }}
                        beforeIcon={
                          <ArrowUpOnSquareStackIcon className="h-4 mr-1" />
                        }
                        loading={publishingLoader}
                        outlined
                        btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                      />

                      <CustomButton
                        text="Use"
                        type="button"
                        onClickBtn={() => setOpenCreateProjectFromTemp(true)}
                        beforeIcon={
                          <ArrowDownCircleIcon className="h-4 mr-1" />
                        }
                        loading={false}
                        outlined
                        btnStyle="whitespace-nowrap text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue"
                      />

                      <CustomButton
                        text="Edit"
                        type="button"
                        onClickBtn={() => setOpenDocumentForm(true)}
                        beforeIcon={<PencilIcon className="h-4 mr-1" />}
                        loading={false}
                        outlined
                        btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}

          <div className='ml-[-4px] mr-[-4px]'>
            {showTempDetail ? (
              <WrapperLoader loading={tempDetailLoader}>
                <TemplateDetailPage
                  gettingProjectTempDetail={gettingProjectTempDetail}
                  tempType={tempType}
                />
              </WrapperLoader>
            ) : (
              <WrapperLoader loading={allTemplatesLoader}>
                <div
                  className="w-full overflow-y-auto"
                  style={{
                    height: isModal
                      ? "calc(100vh - 250px)"
                      : "calc(100vh - 130px)",
                  }}
                >
                  {templatesCompleteList?.length > 0 ? (
                    <div className="grid grid-cols-1 [@media(min-width:1250px)]:grid-cols-3 [@media(min-width:1500px)]:grid-cols-4 [@media(min-width:2000px)]:grid-cols-5 gap-4">
                      {templatesCompleteList?.map(
                        (data: any, index: number) => {
                          return (
                            <TemplateListBox
                              key={index}
                              data={data}
                              onShow={() => {
                                isModal
                                  ? setShowTempDetail(data)
                                  : (tempType === "my" &&
                                      navigate(`/template/${data?.id}`)) ||
                                    (tempType === "community" &&
                                      navigate(
                                        `/community-template/${data?.id}`
                                      ));
                              }}
                              tempType={tempType}
                              onUse={onUsingTemplate}
                            />
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex items-center justify-center"
                      style={{ height: "calc(100vh - 110px)" }}
                    >
                      <CompleteEmptyPage
                        title="Approach documenting strategically"
                        subTitle="Any project you create, any document you create can always be saved as a template for future re-use. From here you can also publish your work and share it with comunity. To create a new template start with creating a new project."
                        btnText="New Template"
                        onClickBtn={null}
                        icon={designTemplateSVG}
                      />
                    </div>
                  )}
                </div>
              </WrapperLoader>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className="font-semibold text-lg mb-1">Create a new Chat</h1>
            <p className="text-xs font-normal">
              Select project and start a new chat
            </p>
          </div>
        }
        size="max-w-lg"
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType="solutionPilot"
        />
      </CustomModal>

      <CustomDrawer
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
        title="Create Project"
        description="Create new Project, provide Title, Status and Category. Title is mandatory field."
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={{
            title: showTempDetail?.title,
            status: "Not Started",
            category: showTempDetail?.category,
          }}
          onSubmitForm={creatingProject}
          loader={formLoader}
          editMode={true}
          documentsList={documentsList}
        />
      </CustomDrawer>

      <CustomModal
        open={openSaveAsTemp}
        onCloseModal={() => setOpenSaveAsTemp(false)}
        title={<h1 className="font-semibold">Save Document as Template</h1>}
        size="max-w-lg"
      >
        <ProjectAsTemplateForm
          onCloseModal={() => setOpenSaveAsTemp(false)}
          loading={saveAsTempLoader}
          onSubmitData={savingDocumentAsTemplateData}
          templateType="community"
          titleValue={showTempDetail?.title}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProjectTemplate}
        deleteLoader={confirmationLoader}
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={
          showTempDetail?.privacy === true
            ? () => publishingTemplate()
            : () => unPublishingTemplate()
        }
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
      />

      <CustomModal
        open={openDocumentForm}
        onCloseModal={() => setOpenDocumentForm(false)}
        title={
          <div>
            <h1 className="font-semibold text-md">{showTempDetail?.title}</h1>{" "}
          </div>
        }
        size="w-[100%] my-0 sm:my-0 h-[100vh] rounded-none"
      >
        <DocumentEdit />
      </CustomModal>

      <CustomDrawer
        open={openCreateProjectFromTemp}
        onCloseModal={() => setOpenCreateProjectFromTemp(false)}
        title="Save as Template"
        description="Save project as template"
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setOpenCreateProjectFromTemp(false)}
          onSubmitForm={creatingProjectFromTemp}
          loader={formLoader}
          projectData={showTempDetail}
        />
      </CustomDrawer>

      <CustomDrawer
        open={publishTempModal}
        onCloseModal={() => setPublishTempModal(false)}
        title="Publish Template"
        description="You can Publish your template for public"
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setPublishTempModal(false)}
          onSubmitForm={publishingProjectTemplate}
          loader={formLoader}
          projectData={showTempDetail}
        />
      </CustomDrawer>
    </>
  );
};

export default TemplatesData;
