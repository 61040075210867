import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import ProjectForm from "../../../projects/CreationForm";
import { updateProject } from "../../../../redux/projects/projectsApi";
import "./style.css";

export default function EditProjectForm() {
  const [formLoader, setFormLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { projectsByIdRes } = useAppSelector((state) => state.projects);
  const { getAccessTokenSilently } = useAuth0();

  const updatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProject({
              body,
              accessToken,
              id: projectsByIdRes?.id,
            })
          );
          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  return (
    <div>
      <h3 className=" text-md mb-2 pb-2 border-b border-zinc-100">
        Project Settings
      </h3>

      <div
        className="project-edit-form subtle-scrollbar overflow-y-auto pr-4"
        style={{ height: "calc(100vh - 102px)", width: "calc(100% + 12px)" }}
      >
        <ProjectForm
          onCloseModal={null}
          editData={projectsByIdRes}
          onSubmitForm={updatingProject}
          loader={formLoader}
          editMode={true}
          isProjectDetail={true}
        />
      </div>
    </div>
  );
}
