import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CustomModal from '../../../components/customModal';
import ArgumentForm from './argumentForm';
import ArgumentBox from '../../../components/argumentBox';
import {
  getTbarArgumentsList,
  createTbarArgument,
  updateTbarArgument,
  deleteTbarArgument,
} from '../../../redux/tbarsArguments/tbarsArgumentsApi';
import { useAppDispatch } from '../../../hooks';
import { getLastIdFromUrl } from '../../../utils/utilities';
import RoundButton from 'src/components/roundButton';
import ProgressBar from '../../../components/progressBar';
import ConfirmationModal from '../../../components/confirmationModal';
import { classNames } from '../../../utils/utilities';

interface AnalysisProps {
  detailsData: any;
}

export default function Analysis({ detailsData }: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [optionA, setOptionA] = useState<any>(null);
  const [optionB, setOptionB] = useState<any>(null);
  const [argumentFormLoader, setArgumentFormLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [optionAList, setOptionAList] = useState<any>(null);
  const [optionBList, setOptionBList] = useState<any>(null);
  const [argumentDeleteLoader, setArgumentDeleteLoader] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gettingArgumentsList = async (accessToken: string, option_id: string, type: string) => {
    await dispatch(
      getTbarArgumentsList({
        accessToken,
        option_id,
        tbar_id: detailsData?.id,
        project_id: getLastIdFromUrl(window.location.pathname),
      })
    )
      .then((res: any) => {
        if (type === 'optionA') {
          setOptionAList(res?.payload?.data);
        }

        if (type === 'optionB') {
          setOptionBList(res?.payload?.data);
        }
      })
      .catch((error: unknown) => console.log('error ==>', error));
  };

  useEffect(() => {
    if (detailsData?.options?.length > 0) {
      let optionAObject: any = { ...detailsData?.options[0] };
      let optionBObject: any = { ...detailsData?.options[1] };

      optionAObject.type = 'optionA';
      optionBObject.type = 'optionB';

      setOptionA(optionAObject);
      setOptionB(optionBObject);
    }
  }, [detailsData]);

  useEffect(() => {
    if (optionA?.option_id || optionB?.option_id) {
      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            if (optionA?.option_id) {
              await gettingArgumentsList(accessToken, optionA?.option_id, 'optionA');
            }

            if (optionB?.option_id) {
              await gettingArgumentsList(accessToken, optionB?.option_id, 'optionB');
            }
          }
        } catch (error) {
          console.error('Error getting access token:', error);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionA, optionB]);

  const calculatingPercentage = (type: string) => {
    if (optionAList?.length > 0 && optionBList?.length > 0) {
      const optionASum = optionAList.reduce((start: number, data: any) => {
        return start + data.argumentWeight;
      }, 0);

      const optionBSum = optionBList.reduce((start: number, data: any) => {
        return start + data.argumentWeight;
      }, 0);

      const allSum = optionASum + optionBSum;

      if (type === 'optionA') {
        return Math.ceil((optionASum / allSum) * 100);
      }

      if (type === 'optionB') {
        return Math.ceil((optionBSum / allSum) * 100);
      }
    } else {
      return '';
    }
  };

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
    setSelectedOption(null);
  };

  const onOpenModal = (option: any) => {
    setSelectedOption(option);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, option: any) => {
    setSelectedData(data);
    setSelectedOption(option);
    setOpenFormModal(true);
  };

  const updatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateTbarArgument({
              body,
              accessToken,
              argument_id: selectedData?.id,
              option_id: selectedOption?.option_id,
              tbar_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          await gettingArgumentsList(accessToken, selectedOption?.option_id, selectedOption?.type);

          onCloseModal();
          setArgumentFormLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createTbarArgument({
              body,
              accessToken,
              tbar_id: detailsData?.id,
              option_id: selectedOption?.option_id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          await gettingArgumentsList(accessToken, selectedOption?.option_id, selectedOption?.type);

          onCloseModal();
          setArgumentFormLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingArgument = () => {
    const fetchData = async () => {
      setArgumentDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            deleteTbarArgument({
              accessToken,
              argument_id: selectedData?.id,
              option_id: selectedOption?.option_id,
              tbar_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          await gettingArgumentsList(accessToken, selectedOption?.option_id, selectedOption?.type);
        }

        setArgumentDeleteLoader(false);
        closeConfirmationModal();
      } catch (error) {
        console.error('Error getting access token:', error);
        setArgumentDeleteLoader(false);
        closeConfirmationModal();
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingArgument(body);
    } else {
      creatingArgument(body);
    }
  };

  const deleteConfirmationModal = (data: any, option: string) => {
    setSelectedData(data);
    setSelectedOption(option);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
    setSelectedOption(null);
  };

  return (
    <>
      <div className='flex items-center justify-center'>
        <div className='flex'>
          <div className='flex items-center'>
            <span className='mr-2 text-xs font-semibold'>{`${calculatingPercentage('optionA')}`}%</span>
            <div
              className='rotate-180'
              style={{ width: '300px' }}
            >
              <ProgressBar
                percent={`${calculatingPercentage('optionA')}`}
                styles='rounded-l-none'
                color='bg-green-600'
              />
            </div>
          </div>

          <div className='flex items-center'>
            <div style={{ width: '300px' }}>
              <ProgressBar
                percent={`${calculatingPercentage('optionB')}`}
                styles='rounded-l-none'
                color='bg-amber-400'
              />
            </div>
            <span className='ml-2 text-xs font-semibold'>{`${calculatingPercentage('optionB')}`}%</span>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2'>
        {/** Pros Column **/}
        <div className='border-r border-gray-200'>
          <div className='bg-white rounded-2xl overflow-hidden mt-4 mr-4 shadow-md'>
            <div className='pt-6 px-6 pb-4'>
              <div className='text-center'>
                <span className='font-semibold text-base'>{optionA?.option_title}</span>
              </div>
            </div>
            <div className='border-t border-gray-200 p-4'>
              <div
                onClick={() => onOpenModal(optionA)}
                className='w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400'
              >
                <RoundButton onClickBtn={() => console.log('clicking...')} />
              </div>
              <div
                className=' overflow-y-auto'
                style={{ height: 'calc(100vh - 255px)' }}
              >
                <ul className='mt-3 grid grid-cols-1'>
                  {optionAList?.length > 0
                    ? optionAList.map((argument: any, index: number) => (
                        <ArgumentBox
                          key={index}
                          id={argument?.id}
                          name={argument.argumentName}
                          classNames={classNames}
                          value={argument.argumentWeight}
                          onEdit={() => onOpenEditModal(argument, optionA)}
                          onDelete={() => deleteConfirmationModal(argument, optionA)}
                          classes='bg-green-600 text-white'
                          deleteLoader={false}
                          selectedData={selectedData}
                        />
                      ))
                    : ''}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/** Cons Column **/}
        <div className='bg-white rounded-2xl overflow-hidden mt-4 ml-4 shadow-md'>
          <div className='pt-6 px-6 pb-4'>
            <div className='text-center'>
              <span className='font-semibold text-base'>{optionB?.option_title}</span>
            </div>
          </div>
          <div className='border-t border-gray-200 p-4'>
            <div
              onClick={() => onOpenModal(optionB)}
              className='w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400'
            >
              <RoundButton onClickBtn={() => console.log('clicking...')} />
            </div>

            <div
              className=' overflow-y-auto'
              style={{ height: 'calc(100vh - 255px)' }}
            >
              <ul className='mt-3 grid grid-cols-1'>
                {optionBList?.length > 0
                  ? optionBList.map((argument: any, index: number) => (
                      <ArgumentBox
                        key={index}
                        id={argument?.id}
                        name={argument.argumentName}
                        classNames={classNames}
                        value={argument.argumentWeight}
                        onEdit={() => onOpenEditModal(argument, optionB)}
                        onDelete={() => deleteConfirmationModal(argument, optionB)}
                        classes='bg-amber-400 text-white'
                        deleteLoader={false}
                        selectedData={selectedData}
                      />
                    ))
                  : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? 'Update' : 'Add'} Argument`}
        size='max-w-lg'
      >
        <ArgumentForm
          onCloseModal={() => onCloseModal()}
          loading={argumentFormLoader}
          onSubmitData={onSubmitForm}
          editData={selectedData}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingArgument}
        deleteLoader={argumentDeleteLoader}
      />
    </>
  );
}
