interface RadioGroupProps {
  onChangeRadio: Function;
  value: string;
  radioList: any[];
}

export default function RadioGroup({
  onChangeRadio,
  value,
  radioList,
}: RadioGroupProps) {
  return (
    <div className="space-y-5 sm:mt-0">
      {radioList.map((item) => (
        <div key={item.id} className="relative flex items-start">
          <div className="absolute flex h-6 items-center">
            <input
              name="privacy"
              type="radio"
              checked={item.value === value}
              onChange={() => onChangeRadio(item.value)}
              className="h-4 w-4 border-gray-300 text-amber-500 focus:ring-amber-500"
            />
          </div>
          <div className="pl-7 text-xs leading-6">
            <label
              htmlFor="private-access"
              className="font-medium text-gray-900"
            >
              {item?.title}
            </label>
            <p className="text-gray-500">
              You are the only one able to access this project
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
