import { ReactNode, ReactElement, ComponentProps } from 'react';
import { classNames } from 'src/utils/utilities';

type ConfigurationItemProps = Readonly<{
  title: string;
  value: number | string;
  description: string;
  color: string;
  className?: string;
}>;

type ConfigurationGridProps = Readonly<{
  title: string;
  children: ReactNode;
  className?: string;
}>;

type ConfigurationItemFullProps = ConfigurationItemProps & Omit<ComponentProps<'li'>, keyof ConfigurationItemProps>;
type ConfigurationGridFullProps = ConfigurationGridProps & Omit<ComponentProps<'div'>, keyof ConfigurationGridProps>;

function ConfigurationItem({
  title,
  value,
  description,
  color,
  className,
  ...rest
}: ConfigurationItemFullProps): ReactElement {
  return (
    <li
      className={classNames('col-span-1 flex rounded-md shadow-sm', className)}
      {...rest}
    >
      <div
        className={classNames(
          color,
          'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
        )}
      >
        {value}
      </div>
      <div className='flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white'>
        <div className='flex-1 px-4 py-2 text-sm'>
          <p className='font-medium text-gray-900 break-words'>{title}</p>
          <p className='text-gray-500 break-words'>{description}</p>
        </div>
      </div>
    </li>
  );
}

function ConfigurationGrid({ title, children, className, ...rest }: ConfigurationGridFullProps): ReactElement {
  return (
    <div
      className={className}
      {...rest}
    >
      <h2 className='text-gray-500 mb-5 text-lg'>{title}</h2>
      <ul className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-3'>{children}</ul>
    </div>
  );
}

export default Object.assign(ConfigurationGrid, { Item: ConfigurationItem });
