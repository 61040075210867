import {
  AdjustmentsHorizontalIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  LightBulbIcon,
  PencilSquareIcon,
  PuzzlePieceIcon,
  ScaleIcon,
  Squares2X2Icon,
  TableCellsIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { ActionGroupProps } from '../../components/itemWithDropdown';
import { useState } from 'react';
import { updateProject, updateProjectDocument } from '../../redux/projects/projectsApi';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { useAuth0 } from '@auth0/auth0-react';
import Toolbar from '../toolbar';

interface DocumentHeaderProps {
  value: string;
  updatingProjectDocument: Function;
  documentSaveLoading: boolean;
  onOpenSaveProjectTemp?: Function;
}

export default function DocumentHeader({
  value,
  updatingProjectDocument,
  documentSaveLoading,
  onOpenSaveProjectTemp,
}: DocumentHeaderProps) {
  const [, setAutoSaveLoader] = useState(false);

  const { projectsByIdRes, selectedDocument } = useAppSelector((state) => state.projects);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const updatingProject = () => {
    const fetchData = async () => {
      setAutoSaveLoader(true);

      const body = {
        title: projectsByIdRes?.title,
        status: projectsByIdRes?.status,
        category: projectsByIdRes?.category,
        complexity: projectsByIdRes?.complexity,
        description: projectsByIdRes?.description,
        autosave: !projectsByIdRes?.autosave,
      };
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProject({
              body,
              accessToken,
              id: projectsByIdRes?.id,
            })
          );
          setAutoSaveLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setAutoSaveLoader(false);
      }
    };

    fetchData();
  };

  const updatingDocument = async (changes: Record<string, any>) => {
    if (!selectedDocument || !projectsByIdRes) return;

    const body = {
      ...selectedDocument,
      ...changes,
    };

    try {
      const accessToken = await getAccessTokenSilently();

      if (accessToken) {
        await dispatch(
          updateProjectDocument({
            body,
            accessToken,
            project_id: projectsByIdRes.id,
            document_id: selectedDocument.id,
          })
        );
      }
    } catch (error) {
      console.error('Error getting access token:', error);
    }
  };

  const saveAsList = [
    {
      id: 1,
      name: 'Template',
      onActionClick: () => (onOpenSaveProjectTemp ? onOpenSaveProjectTemp(true) : console.log('...')),
      icon: <LightBulbIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 2,
      name: 'Design Standard',
      onActionClick: () => console.log('...'),
      icon: <PuzzlePieceIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
  ];

  const validateList = [
    {
      id: 1,
      name: '/Check for limitations',
      onActionClick: () => console.log('...'),
    },
    {
      id: 2,
      name: '/Check for spelling mistakes',
      onActionClick: () => console.log('...'),
    },
    {
      id: 3,
      name: '/Check for improvements',
      onActionClick: () => console.log('...'),
    },
  ];

  const generateList = [
    {
      id: 1,
      name: 'Diagram',
      onActionClick: () => console.log('...'),
      icon: <CubeIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 2,
      name: 'T-Chart',
      onActionClick: () => console.log('...'),
      icon: <ScaleIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 3,
      name: 'Pros & Cons',
      onActionClick: () => console.log('...'),
      icon: <TableCellsIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 4,
      name: 'Decision Martix',
      onActionClick: () => console.log('...'),
      icon: <AdjustmentsHorizontalIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 5,
      name: 'SWOT',
      onActionClick: () => console.log('...'),
      icon: <Squares2X2Icon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
  ];

  const renderList: ActionGroupProps[] = [
    new Toolbar.ChatSelectorItem({
      id: 1,
      selected: selectedDocument?.ai_conversation_id,
      setSelected: (chatId) => updatingDocument({ ai_conversation_id: chatId }),
    }),
    {
      id: 2,
      name: 'Generate',
      actions: generateList,
      width: 110,
    },
    new Toolbar.ValidateItem({
      id: 3,
      actions: validateList,
    }),
    {
      id: 4,
      name: 'Save As',
      actions: saveAsList,
      width: 110,
    },
    new Toolbar.ButtonItem({
      id: 5,
      name: 'Save Changes',
      onActionClick: () => updatingProjectDocument(),
      loading: documentSaveLoading,
      iconComponent: DocumentDuplicateIcon,
      width: 145,
    })
  ];

  const actionsList = [
    new Toolbar.ButtonItem({
      id: 6,
      name: 'Edit',
      onActionClick: () => console.log('...'),
      iconComponent: PencilSquareIcon,
      width: 0
    }),
    new Toolbar.ButtonItem({
      id: 7,
      name: 'Delete',
      onActionClick: () => console.log('...'),
      iconComponent: TrashIcon,
      width: 0
    })
  ];

  return (
    <>
      <Toolbar>
        <Toolbar.Left>
          <Toolbar.Switch
            enabled={projectsByIdRes?.autosave}
            setEnabled={() => updatingProject()}
          >
            Auto Save
          </Toolbar.Switch>
          <Toolbar.Switch
            setEnabled={() => updatingDocument({ ai_suggestions: !selectedDocument?.ai_suggestions })}
            enabled={!!selectedDocument?.ai_suggestions}
          >
            Assistant
          </Toolbar.Switch>
        </Toolbar.Left>
        <Toolbar.Right
          visibleItems={renderList}
          hiddenItems={actionsList}
        />
      </Toolbar>
    </>
  );
}
