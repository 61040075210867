import React, { Key, useEffect, useRef, useState } from 'react';

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import MenuSubItemsList from './menuSubItemsList';
import Tooltip from '../tooltipWrapper';
import { useAppSelector } from '../../hooks';

interface SidebarProps {
    classNames: Function;
    navigationsList: Array<any>;
    title: string;
    collapseSidebar: boolean;
}

interface MenuItemProps {
    classNames: Function;
    collapseSidebar: boolean;
    item: any;
}

function MenuItem({ classNames, collapseSidebar, item }: Readonly<MenuItemProps>) {
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const subMenuRef = useRef<HTMLDivElement | null>(null);

    const { currentPage } = useAppSelector((state) => state.profile);
    const [isSubMenuFloating, setIsSubMenuFloating] = useState(false);

    const hasChildren = item.childrens && item.childrens?.length > 0;

    const isSelected = !!(
        currentPage &&
        (currentPage.href === item.href || item.childrens?.some((child: any) => currentPage.href === child.href))
    );

    useEffect(() => {
        setIsSubMenuFloating(false);
    }, [collapseSidebar]);

    const handleMouseEnter = () => {
        if (collapseSidebar) {
            setIsSubMenuFloating(hasChildren);
        }
    };

    const handleMouseLeave = () => {
        if (collapseSidebar) {
            setIsSubMenuFloating(hasChildren && !!(linkRef.current?.matches(':hover') || subMenuRef.current?.matches(':hover')));
        }
    };

    return (
        <li
            key={item.name}
            className={classNames('relative', collapseSidebar ? 'ml-0' : '-ml-[14px]')}
        >
            <Tooltip
                text={
                    (collapseSidebar && !isSubMenuFloating) ? <span className='whitespace-nowrap'>{item.name}</span> : ''
                }
                position='right'
            >
                <Link
                    ref={linkRef}
                    to={item?.href}
                    // onMouseOver={(e) => {
                    //   e.preventDefault(); // Prevent default behavior
                    //   if (item.childrens && item.childrens?.length > 0) {
                    //     if (collapseSidebar) {
                    //       handleMenuClick(index);
                    //     }
                    //   }
                    // }}
                    // onMouseLeave={(e) => {
                    //   e.preventDefault(); // Prevent default behavior
                    //   if (item.childrens && item.childrens?.length > 0) {
                    //     if (collapseSidebar) {
                    //       handleMenuClick(0);
                    //     }
                    //   }
                    // }}
                    className={classNames(
                        isSelected
                            ? 'bg-white text-amber-500 shadow-md border-l-2 border-amber-500'
                            : 'text-zinc-900 hover:text-amber-500 hover:bg-zinc-100 border-transparent opacity-90 hover:opacity-100',
                        `flex items-center rounded-md p-2 text-xs leading-5 font-medium cursor-pointer border-l-2 ${
                            collapseSidebar ? ' justify-center' : 'justify-between'
                        }`
                    )}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='flex items-center justify-center'>
                        <span
                            className={classNames(
                                collapseSidebar ? '' : 'mr-2',
                                item.href === currentPage?.href ? 'text-amber-500' : 'hover:text-amber-500'
                            )}
                        >
                            <item.icon className='w-[17px] h-[17px]' />
                        </span>

                        {!collapseSidebar && <span>{item.name}</span>}
                    </div>

                    {hasChildren && (
                        <ChevronRightIcon
                            className={classNames(
                                'h-[14px]',
                                item.href === currentPage?.href ? 'text-amber-500' : 'hover:text-amber-500',
                                `transition-all rotate-${isSelected ? 90 : 0}`
                            )}
                        />
                    )}
                </Link>
            </Tooltip>

            {/* Sub Menu */}
            {hasChildren && (collapseSidebar ? isSubMenuFloating : isSelected) && (
                <div
                    ref={subMenuRef}
                    className={classNames(isSubMenuFloating && 'pl-[9px] absolute left-full top-[0] bg-transparent')}
                    onMouseLeave={handleMouseLeave}
                >
                    <ul
                        className={classNames(
                            'space-y-1 rounded-md',
                            isSubMenuFloating
                                ? 'bg-white shadow-lg w-[200px] border border-zinc-200 p-3'
                                : 'p-3 pl-6 bg-slate-100'
                        )}
                    >
                        {item.childrens.map(
                            (subitem: { id: Key | null | undefined; title: string; menuList: any[][] }) => (
                                <MenuSubItemsList
                                    key={subitem.id}
                                    classNames={classNames}
                                    collapseSidebar={collapseSidebar}
                                    data={subitem}
                                />
                            )
                        )}
                    </ul>
                </div>
            )}
        </li>
    );
}

export default function MenuItemsList({ classNames, navigationsList, title, collapseSidebar }: SidebarProps) {
    return (
        <li>
            {!collapseSidebar && (
                <div className='text-xs font-semibold leading-6 text-slate-400 mb- uppercase'>{title}</div>
            )}

            <ul className={`space-y-1 pl-0`}>
                {navigationsList.map((item: any) => (
                    <MenuItem
                        key={item.name}
                        item={item}
                        collapseSidebar={collapseSidebar}
                        classNames={classNames}
                    />
                ))}
            </ul>
        </li>
    );
}
