import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import Detials from "./Details";
import Analysis from "./Analysis";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getSwotById } from "../../redux/swot/swotApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../../components/wrapperLoader";
// import DetailPageHead from "../../components/detialPageHead";
import { getQueryParams } from "../../utils/utilities";
import { SwotToolbar } from "./Toolbar";

// const tabs: any = [
//   { id: 1, name: "Details", current: true },
//   { id: 2, name: "Analysis", current: false },
// ];

export default function SWOTDetails() {
  // const [swotTabsList, setSwotTabsList] = useState(tabs);
  // const [activeId, setActiveId] = useState(1);
  const [swotByIdLoader, setSwotByIdLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { swotByIdRes } = useAppSelector((state) => state.swot);
  const queryStringObj = getQueryParams(window.location.href);

  useEffect(() => {
    const fetchData = async () => {
      setSwotByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getSwotById({
              accessToken,
              id: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setSwotByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setSwotByIdLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, dispatch]);

  // const onSelectTab = (id: number) => {
  //   const updatedTabsList = swotTabsList.map((item: any) => ({
  //     ...item,
  //     current: item.id === id,
  //   }));

  //   setSwotTabsList(updatedTabsList);
  //   setActiveId(id);
  // };

  // const pageTitle = swotByIdRes?.details?.title;

  return (
    <WrapperLoader loading={swotByIdLoader}>
      <SwotToolbar />
      <div className="p-6">
        <div>
          <Analysis detailsData={swotByIdRes} />
        </div>
      </div>
    </WrapperLoader>
  );
}
