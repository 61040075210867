import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import React, { ReactElement, ReactNode } from 'react';

import { DROPDOWN_TRANSITION_CLASSES } from 'src/config';
import { classNames } from '../../utils/utilities';

export interface ButtonChildrenProps {
  name: string;
  icon?: ReactNode;
}

export interface ActionProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onActionClick?: () => void;
}

export interface RequiredActionGroupProps extends ActionProps {
  actions: ActionProps[];
  renderInToolbar: (props: RequiredActionGroupProps) => ReactNode;
  renderInDropdown: (props: RequiredActionGroupProps & DropdownRenderProps) => ReactElement;
  width: number;
}

export interface DropdownRenderProps {
  renderButtonContent: (props: ButtonChildrenProps) => ReactElement;
}

export type ActionGroupProps = ActionProps & Partial<RequiredActionGroupProps>;

type ItemProps = RequiredActionGroupProps | ActionProps;

interface ItemWithDropdownProps {
  title?: string;
  actionsData: ItemProps[];
  children: React.ReactNode;
  menuPosition?: string;
  isNested?: boolean;
  buttonClassName?: string;
}

function isActionGroup(props: ItemProps): props is RequiredActionGroupProps {
  return 'renderInDropdown' in props;
}

export default function ItemWithDropdown({
  title,
  actionsData,
  children,
  menuPosition,
  buttonClassName,
  isNested = false,
}: ItemWithDropdownProps) {
  return (
    <Menu as='div'>
      <MenuButton
        className={classNames(
          'w-full flex items-center rounded-full text-customLightBlue hover:text-gray-600',
          buttonClassName
        )}
      >
        {children}
      </MenuButton>

      <Transition
        as='div'
        {...DROPDOWN_TRANSITION_CLASSES}
      >
        <MenuItems
          className={classNames(
            `absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white`,
            `shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${menuPosition || ''}`,
            'divide-y divide-gray-300'
          )}
          anchor={isNested ? 'left start' : undefined}
        >
          {!isNested && title && (
            <div className='px-5 py-3'>
              <span className='text-sm text-customDarkBlue font-semibold'>{title}</span>
            </div>
          )}
          <div className='py-1'>
            {actionsData?.map((item, index) => {
              const renderContent = ({ icon, name }: ButtonChildrenProps) => (
                <span
                  className={classNames(
                    'text-gray-700 transition-colors data-[active]:bg-gray-100 data-[active]:text-customDarkBlue',
                    'group flex items-center px-4 py-3 text-xs rounded-md',
                    'hover:text-customDarkBlue'
                  )}
                >
                  {icon}
                  {name}
                </span>
              );

              return (
                <div
                  className='px-1 cursor-pointer'
                  onClick={() => item.onActionClick?.()}
                >
                  <MenuItem key={index}>
                    {() => {
                      if (isActionGroup(item)) {
                        return item.renderInDropdown({
                          ...item,
                          renderButtonContent: renderContent,
                        });
                      }

                      return renderContent({ name: item.name, icon: item.icon });
                    }}
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
