import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  ArrowDownCircleIcon,
  PencilIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";

import TemplateDetailPage from "../../components/templateDetailPage";
import {
  deleteTemplate,
  getTemplateById,
  createProjectFromTemplate,
  getPublicTemplateById,
  createProjectFromPublicTemplate,
  cloneFromPublicTemplate,
} from "../../redux/templates/templatesApi";
import CustomButton from "../../components/customButton";
import CustomModal from "../../components/customModal";
import ProjectsSelection from "../templates/ProjectsSelectionForm";
import { createConversation } from "../../redux/chatGPT/chatGPTApi";
import ProjectAsTemplateForm from "../templates/ProjectAsTemplateForm";
import {
  publishUserAiAssistant,
  unPublishUserAiAssistant,
} from "../../redux/userAiAssistants/userAiAssistantsApi";
import ConfirmationModal from "../../components/confirmationModal";
import PublishConfirmation from "../../components/publishConfirmation";
import DocumentEdit from "../templates/DocumentEdit";
import { getLastIdFromUrl, getFirstPathFromUrl } from "../../utils/utilities";
import SaveProjectAsTemplate from "../templates/SaveProjectAsTemplate";
import CustomDrawer from "../../components/customDrawer";
import {
  publishProjectTemplate,
  unPublishProjectTemplate,
} from "../../redux/templates/templatesApi";
import { useNotification } from "../../components/apiNotification";

const TemplatesDetails = () => {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState("");
  const [openSaveAsTemp, setOpenSaveAsTemp] = useState(false);
  const [saveAsTempLoader, setSaveAsTempLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [openDocumentForm, setOpenDocumentForm] = useState(false);
  const [tempDetailLoader, setTempDetailLoader] = useState(false);
  const [openCreateProjectFromTemp, setOpenCreateProjectFromTemp] =
    useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [publishTempModal, setPublishTempModal] = useState(false);
  const [cloneTemplateLoader, setCloneTemplateLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { templateByIdRes, getPublicTemplateByIdRes } = useAppSelector(
    (state) => state.templates
  );
  const { addNotification } = useNotification();

  const firstPath = getFirstPathFromUrl(window.location.pathname);

  const onClosingProjectSelection = () => {
    setSelectedAIChatId("");
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`);
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  const savingDocumentAsTemplateData = (
    project_id: string,
    templateType: string
  ) => {
    const fetchData = async () => {
      try {
        setSaveAsTempLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/project/${project_id}`);
              }
              setOpenSaveAsTemp(false);
              setSaveAsTempLoader(false);
            })
            .catch(() => {
              setSaveAsTempLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  const deletingProjectTemplate = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteTemplate({
              accessToken,
              id: templateByIdRes?.project_template?.id,
            })
          )
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              navigate("/templates");
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const gettingProjectTempDetail = () => {
    const fetchData = async () => {
      setTempDetailLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (firstPath === "template") {
            await dispatch(
              getTemplateById({
                accessToken,
                id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          if (firstPath === "community-template") {
            await dispatch(
              getPublicTemplateById({
                accessToken,
                id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          setTempDetailLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setTempDetailLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const publishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            publishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const unPublishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const creatingProjectFromTemp = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        debugger;
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          if (firstPath === "community-template") {
            await dispatch(
              createProjectFromPublicTemplate({
                body,
                accessToken,
                id:
                  getPublicTemplateByIdRes?.project_template?.id ||
                  getLastIdFromUrl(window.location.pathname),
              })
            ).then((res) => {
              if (res) {
                if (res?.payload?.data) {
                  setOpenCreateProjectFromTemp(false);
                  setFormLoader(false);
                  navigate(
                    `/projects/${res?.payload?.data?.id}?active=documents&id=${res?.payload?.data?.components?.document?.id}`
                  );
                }
              }
            });
          }

          if (firstPath === "template") {
            await dispatch(
              createProjectFromTemplate({
                body,
                accessToken,
                id:
                  templateByIdRes?.project_template?.id ||
                  getLastIdFromUrl(window.location.pathname),
              })
            ).then((res) => {
              if (res) {
                if (res?.payload?.data) {
                  setOpenCreateProjectFromTemp(false);
                  setFormLoader(false);
                  navigate(`/projects/${res?.payload?.data?.id}`);
                }
              }
            });
          }
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const publishingProjectTemplate = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      setPublishingLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            publishProjectTemplate({
              body,
              accessToken,
              id: templateByIdRes?.project_template?.id,
            })
          );

          setFormLoader(false);
          setPublishingLoader(false);
          setPublishTempModal(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const unPublishingTemplate = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishProjectTemplate({
              accessToken,
              id: templateByIdRes?.project_template?.public_template_ref,
            })
          );

          setPublishConfirmation(false);
          setPublishingLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const cloningProjectTemplate = () => {
    const fetchData = async () => {
      setCloneTemplateLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            cloneFromPublicTemplate({
              accessToken,
              id: getPublicTemplateByIdRes?.project_template?.id,
            })
          )
            .then((res) => {
              if (res?.payload?.data) {
                const response = res?.payload?.data;
                setCloneTemplateLoader(false);
                addNotification(
                  "Template Saved Successfully",
                  "We saved this template in your private Project Templates. Click the button to see the details.",
                  "Show Template",
                  () => navigate(`/template/${response?.project_template?.id}`)
                );
              }
            })
            .catch(() => {
              setCloneTemplateLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setCloneTemplateLoader(false);
      }
    };

    fetchData();
  };

  return (
    <WrapperLoader loading={tempDetailLoader}>
      <div className="px-4">
        <div className="py-3 border-b border-gray-200 text-right">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-sm font-semibold flex items-center text-customLightBlue">
              <LightBulbIcon className="mr-2 h-5 text-zinc-500 " />
              {firstPath === "template" && (
                <span>{templateByIdRes?.project_template?.title}</span>
              )}

              {firstPath === "community-template" && (
                <span>{getPublicTemplateByIdRes?.project_template?.title}</span>
              )}
            </h3>

            <div className="flex">
              {firstPath === "template" && (
                <>
                  <CustomButton
                    text="Delete"
                    type="button"
                    onClickBtn={() => deletingModalOpen(showTempDetail?.id)}
                    beforeIcon={<TrashIcon className="h-4 mr-1" />}
                    loading={false}
                    outlined
                    btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                  />

                  <CustomButton
                    text={
                      templateByIdRes?.project_template?.privacy === true
                        ? "Publish"
                        : "Unpublish"
                    }
                    type="button"
                    onClickBtn={
                      templateByIdRes?.project_template?.privacy === true
                        ? () => setPublishTempModal(true)
                        : () => unPublishingTemplate()
                    }
                    beforeIcon={
                      <ArrowUpOnSquareStackIcon className="h-4 mr-1" />
                    }
                    loading={publishingLoader}
                    outlined
                    btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                  />
                </>
              )}

              {firstPath === "community-template" && (
                <CustomButton
                  text="Save"
                  type="button"
                  onClickBtn={() => cloningProjectTemplate()}
                  beforeIcon={<ArrowDownCircleIcon className="h-4 mr-1" />}
                  loading={cloneTemplateLoader}
                  outlined
                  btnStyle="shrink-0 border-zinc-300 text-customLightBlue hover:text-amber-400"
                />
              )}

              <CustomButton
                text="Clone"
                type="button"
                onClickBtn={() => setOpenCreateProjectFromTemp(true)}
                beforeIcon={<LightBulbIcon className="h-4 mr-1" />}
                loading={false}
                btnStyle="ml-3"
              />

              {firstPath === "template" && (
                <CustomButton
                  text="Edit"
                  type="button"
                  onClickBtn={() => setOpenDocumentForm(true)}
                  beforeIcon={<PencilIcon className="h-4 mr-1" />}
                  loading={false}
                  outlined
                  btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                />
              )}
            </div>
          </div>
        </div>

        <TemplateDetailPage
          gettingProjectTempDetail={gettingProjectTempDetail}
        />
      </div>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className="font-semibold text-lg mb-1">Create a new Chat</h1>
            <p className="text-xs font-normal">
              Select project and start a new chat
            </p>
          </div>
        }
        size="max-w-lg"
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType="solutionPilot"
        />
      </CustomModal>

      <CustomModal
        open={openSaveAsTemp}
        onCloseModal={() => setOpenSaveAsTemp(false)}
        title={<h1 className="font-semibold">Save Document as Template</h1>}
        size="max-w-lg"
      >
        <ProjectAsTemplateForm
          onCloseModal={() => setOpenSaveAsTemp(false)}
          loading={saveAsTempLoader}
          onSubmitData={savingDocumentAsTemplateData}
          templateType="community"
          titleValue={showTempDetail?.title}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProjectTemplate}
        deleteLoader={confirmationLoader}
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={
          showTempDetail?.privacy === true
            ? () => publishingAIAssistant()
            : () => unPublishingAIAssistant()
        }
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
      />

      <CustomModal
        open={openDocumentForm}
        onCloseModal={() => setOpenDocumentForm(false)}
        title={
          <div>
            <h1 className="font-semibold text-md">{showTempDetail?.title}</h1>{" "}
          </div>
        }
        size="w-[100%] my-0 sm:my-0 h-[100vh] rounded-none"
      >
        <DocumentEdit />
      </CustomModal>

      <CustomDrawer
        open={openCreateProjectFromTemp}
        onCloseModal={() => setOpenCreateProjectFromTemp(false)}
        title="Create Project from Template"
        description="Create a new project from a template"
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setOpenCreateProjectFromTemp(false)}
          onSubmitForm={creatingProjectFromTemp}
          loader={formLoader}
          projectData={getPublicTemplateByIdRes?.project_template}
        />
      </CustomDrawer>

      <CustomDrawer
        open={publishTempModal}
        onCloseModal={() => setPublishTempModal(false)}
        title="Publish Template"
        description="You can Publish your template for public"
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setPublishTempModal(false)}
          onSubmitForm={publishingProjectTemplate}
          loader={formLoader}
          projectData={templateByIdRes?.project_template}
        />
      </CustomDrawer>
    </WrapperLoader>
  );
};

export default TemplatesDetails;
