import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { DRAWER_WIDTH } from 'src/config';
import { classNames } from 'src/utils/utilities';

interface CustomDrawerProps {
  open: boolean;
  onCloseModal: () => void;
  title: any;
  description: string;
  children: ReactNode;
  width?: number;
  headerClassName?: string;
}

export default function CustomDrawer({
  open,
  onCloseModal,
  title,
  description,
  children,
  width = DRAWER_WIDTH,
  headerClassName,
}: CustomDrawerProps) {
  return (
    <Transition
      show={open}
      as={Fragment}
    >
      <Dialog
        as='div'
        className='relative z-50'
        onClose={onCloseModal}
      >
        <TransitionChild
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div
              className='pointer-events-none fixed inset-y-0 right-0 flex pl-10'
              style={{ maxWidth: `${width}px`, width: '100%' }}
            >
              <TransitionChild
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <DialogPanel className='pointer-events-auto relative w-full'>
                  {/* Header */}
                  <div
                    className={classNames(
                      'bg-gradient-to-r from-[#d9ebf8] to-[#f8edf8] px-4 py-6 sm:px-6',
                      headerClassName
                    )}
                  >
                    <div className='flex items-start justify-between space-x-3'>
                      <div className='space-y-1'>
                        <DialogTitle className='text-base font-semibold leading-6 text-customDarkBlue'>
                          {title}
                        </DialogTitle>
                        <p className='text-xs text-customLightBlue'>{description}</p>
                      </div>
                      <div className='flex h-7 items-center'>
                        <button
                          type='button'
                          className='relative text-gray-400 hover:text-customLightBlue'
                          onClick={onCloseModal}
                        >
                          <span className='absolute -inset-2.5' />
                          <span className='sr-only'>Close panel</span>
                          <XMarkIcon
                            className='h-6 w-6'
                            aria-hidden='true'
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
