import { CheckIcon, DocumentTextIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, PropsWithChildren, ReactNode } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';

import type { AnchorPropsWithSelection } from '@headlessui/react/dist/internal/floating';
import { classNames } from '../../utils/utilities';
import { DROPDOWN_TRANSITION_CLASSES } from 'src/config';
import { noop } from 'lodash';

export type Option = {
  id: string;
  title: string;
};

type BaseProps = {
  optionsList: Option[];
  onAddingDocument?: any;
  btnText: string;
  title?: string;
  onSelectAssistant?: Function;
  className?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  anchor?: AnchorPropsWithSelection;
  dropdownPosition?: 'left' | 'right';
  isNested?: boolean;
  children?: ReactNode;
};

export type SingleOptionProps<O> = {
  multiple?: false;
  selected?: O | null;
  setSelected?: (option: O) => void;
};

export type MultipleOptionsProps<O> = {
  multiple: true;
  selected: O[];
  setSelected?: (options: O[]) => void;
};

export type DropdownSelectionOptionProps<O extends Option> = BaseProps &
  (SingleOptionProps<O> | MultipleOptionsProps<O>);

const MAX_OPTIONS_BEFORE_SCROLL = 6;

export default function DropdownSelectionOption<O extends Option>({
  optionsList,
  multiple = false,
  selected,
  setSelected,
  onAddingDocument,
  btnText,
  title,
  onSelectAssistant,
  anchor,
  dropdownPosition = 'right',
  isNested = false,
  className,
  buttonClassName,
  dropdownClassName,
  children,
}: PropsWithChildren<DropdownSelectionOptionProps<O>>) {
  return (
    <Listbox
      multiple={multiple}
      value={selected}
      onChange={setSelected || noop}
    >
      {({ open }) => (
        <>
          <div className={classNames('relative', className)}>
            <ListboxButton
              className={classNames(
                'relative bg-transparent hover:bg-transparent cursor-pointer rounded-md text-left text-customLightBlue  sm:text-xs sm:leading-6',
                buttonClassName
              )}
            >
              {children || (
                <div className='flex items-center text-xs'>
                  <span className='block truncate line-clamp-1'>
                    {(() => {
                      if (Array.isArray(selected)) {
                        if (selected.length === 0) return title;

                        return selected.length === 1 ? selected[0].title : `${selected.length} doc(s) selected`;
                      }

                      return selected?.title || title;
                    })() || 'Loading...'}
                  </span>
                  <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' />
                </div>
              )}
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              {...DROPDOWN_TRANSITION_CLASSES}
            >
              <ListboxOptions
                anchor={anchor}
                className={classNames(
                  'absolute w-[350px] z-10 mt-2 rounded-md bg-white text-base sm:text-xs',
                  'shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  'divide-y divide-gray-300',
                  dropdownPosition === 'left' ? 'right-0' : 'left-0',
                  dropdownClassName
                )}
              >
                {!isNested && title && (
                  <div className='px-5 py-3'>
                    <span className='text-sm text-customDarkBlue font-semibold'>{title}</span>
                  </div>
                )}
                <div
                  className='p-1 overflow-auto'
                  style={{ maxHeight: MAX_OPTIONS_BEFORE_SCROLL * 32 + 8 }}
                >
                  {optionsList?.map((item) => {
                    const isSelected = Array.isArray(selected)
                      ? selected.some(({ id }) => id === item.id)
                      : selected?.id === item.id;

                    return (
                      <ListboxOption
                        onClick={onSelectAssistant ? () => onSelectAssistant(item) : () => console.log('....')}
                        key={item.id}
                        className={() =>
                          classNames(
                            isSelected ? 'text-customDarkBlue' : 'text-customDarkBlue',
                            'relative cursor-pointer select-none py-2 px-4 rounded-md',
                            'transition-colors hover:bg-gray-100 hover:text-customDarkBlue'
                          )
                        }
                        value={item}
                      >
                        <>
                          <span
                            className={classNames(
                              isSelected ? 'font-semibold' : 'font-normal',
                              'pl-4 truncate flex items-center'
                            )}
                          >
                            <div className='w-4 mr-2'>
                              <DocumentTextIcon
                                className={`h-4 w-4 ${isSelected ? 'text-customLightBlue' : 'text-zinc-400'} `}
                              />
                            </div>
                            <span className='line-clamp-1'>{item.title}</span>
                          </span>

                          <div
                            className={classNames(
                              'absolute top-1/2 left-2 -translate-y-1/2',
                              'flex items-center',
                              isSelected ? 'text-amber-600' : 'text-customLightBlue',
                              multiple && 'size-4 rounded border bg-white'
                            )}
                          >
                            <CheckIcon
                              className={classNames('size-5 opacity-0 transition-opacity', isSelected && 'opacity-100')}
                            />
                          </div>
                        </>
                      </ListboxOption>
                    );
                  })}
                </div>

                {onAddingDocument && (
                  <div className='p-1'>
                    <div
                      className={classNames(
                        selected ? 'font-semibold' : 'font-normal',
                        'truncate flex items-center bg-white text-gray-700 py-2 px-4 rounded-md',
                        'cursor-pointer transition-colors hover:bg-gray-100 hover:text-customDarkBlue'
                      )}
                      onClick={() => onAddingDocument()}
                    >
                      <PlusCircleIcon className={`h-5 w-5 mr-2 text-zinc-500`} />
                      <span>{btnText}</span>
                    </div>
                  </div>
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
