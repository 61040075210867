import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ChevronLeftIcon,
  ArrowDownCircleIcon,
  LinkIcon
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../wrapperLoader";
import {
  getPrivateDocumentTemplate,
  getPublicTemplateDocument,
} from "../../redux/templates/templatesApi";
import CustomButton from "../customButton";
import HtmlRenderer from "../HTMLRenderer";
import ComplexityStatus from "../complexityStatus";

import twitterIcon from "../../assets/images/twitter-logo.png";
import linkedInIcon from "../../assets/images/linkedin-logo.png";

interface SingleDocumentDetailsProps {
  templateType: string;
  data: any;
  onUse: Function;
  onBack: Function;
}

const SingleDocumentDetails = ({
  templateType,
  data,
  onUse,
  onBack,
}: SingleDocumentDetailsProps) => {
  const [documentDetailLoader, setDocumentDetailLoader] = useState(false);
  const [documentData, setDocumentData] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getPrivateDocumentTemplateRes, getPublicTemplateDocumentRes } =
    useAppSelector((state) => state.templates);

  useEffect(() => {
    const fetchData = async () => {
      setDocumentDetailLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          if (templateType === "my") {
            await dispatch(
              getPrivateDocumentTemplate({
                accessToken,
                document_id: data?.id,
              })
            );
          }

          if (templateType === "community") {
            await dispatch(
              getPublicTemplateDocument({
                accessToken,
                template_id: data?.project_template_id,
                template_document_id: data?.id,
              })
            );
          }

          setDocumentDetailLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDocumentDetailLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templateType === "my") {
      setDocumentData(getPrivateDocumentTemplateRes);
    }
    if (templateType === "community") {
      setDocumentData(getPublicTemplateDocumentRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPrivateDocumentTemplateRes, getPublicTemplateDocumentRes]);

  return (
    <WrapperLoader loading={documentDetailLoader}>
      <div className="w-full">
        <div className="grid grid-cols-4">
          <div className=" col-span-3 border-r border-zinc-200 pr-7 pl-3">
            <div>
              <div className="mb-4 pb-4 border-b border-gray-200 text-right">
                <div className="flex items-center justify-between w-full">
                  <CustomButton
                    text="Back"
                    type="button"
                    onClickBtn={() => onBack()}
                    beforeIcon={<ChevronLeftIcon className="h-4 mr-1" />}
                    outlined
                    btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue border-none"
                  />

                  <div className="flex">
                    <CustomButton
                      text="Use"
                      type="button"
                      onClickBtn={() => onUse(documentData)}
                      beforeIcon={<ArrowDownCircleIcon className="h-4 mr-1" />}
                      loading={false}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white border border-zinc-100 mt-2 px-7 py-6 rounded-xl shadow-md text-xs leading-6">
                <HtmlRenderer htmlString={documentData?.content || ""} />
              </div>
            </div>
          </div>
          <div className=" col-span-1 px-6">
            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Publisher
              </div>

              <div className="text-xs">Community</div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Version
              </div>
              <div className="flex items-center mb-2 text-xs">1.0.0</div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Last Update
              </div>
              <div className="flex items-center mb-2 text-xs">
                December 26th, 2024
              </div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Downloads
              </div>
              <div className="flex items-center mb-2 text-xs">1000</div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Complexity
              </div>
              <div className="flex items-center mb-2 text-xs">
                <ComplexityStatus status={documentData?.complexity} />
              </div>
            </div>

            <div>
              <div className="text-gray-400 mb-2 text-xs uppercase">Share</div>
              <div className="flex items-center">
                <div className="cursor-pointer mr-2 hover:scale-110">
                  <img
                    className="h-[15px]"
                    src={twitterIcon}
                    alt="Twitter Logo"
                  />
                </div>
                <div className="cursor-pointer mr-2 hover:scale-110">
                  <img
                    className="h-[15px]"
                    src={linkedInIcon}
                    alt="LinkedIn Logo"
                  />
                </div>
                <div className="cursor-pointer mr-2 hover:scale-110">
                  <LinkIcon className="h-[15px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperLoader>
  );
};

export default SingleDocumentDetails;
