import { ComponentPropsWithoutRef, ElementType, FC, Fragment, SVGProps, useState } from 'react';
import SwitchSlide from '../switchSlide';
import { classNames } from 'src/utils/utilities';
import { useResizeObserver } from 'src/hooks/useResizeObserver';
import { useThrottledCallback } from 'use-debounce';
import ItemWithDropdown, {
  RequiredActionGroupProps,
  ActionProps,
  DropdownRenderProps,
  ActionGroupProps,
} from '../itemWithDropdown';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import CustomButton from '../customButton';
import { ChatSelector, ChatSelectorProps } from '../chatSelector';
import { DocumentsSelector, DocumentsSelectorProps } from '../documentsSelector';
import { Option } from '../dropdownSelectionOption';

type Props<T extends Record<string, unknown>, E extends ElementType> = Readonly<T> &
  Omit<ComponentPropsWithoutRef<E>, keyof T>;

type SwitchProps = {
  enabled?: boolean;
  setEnabled: VoidFunction;
};

export type ToolbarItemType = ActionGroupProps | null | undefined | false;

type ToolbarRightProps = {
  visibleItems?: ToolbarItemType[];
  hiddenItems?: ToolbarItemType[];
};

type ItemOptions<T extends Record<string, unknown> = {}> = {
  id: number;
  actions?: ActionProps[];
} & T;

/* ********************** */
/* COMPONENTS */
/* ********************** */

function Switch({ enabled = false, setEnabled, children, className, ...props }: Props<SwitchProps, 'div'>) {
  return (
    <div
      className={classNames('shrink-0 flex items-center mr-4', className)}
      {...props}
    >
      <SwitchSlide
        setEnabled={setEnabled}
        enabled={enabled}
      />
      <span className='ml-2 text-xs text-customLightBlue'>{children}</span>
    </div>
  );
}

function ToolbarLeft({ className, children, ...props }: Props<{}, 'div'>) {
  return (
    <div
      className={classNames('shrink-0 flex items-center bg-white z-10', className)}
      {...props}
    >
      {children}
    </div>
  );
}

function ToolbarRight({
  visibleItems = [],
  hiddenItems = [],
  className,
  children,
  ...props
}: Props<ToolbarRightProps, 'div'>) {
  const validVisibleItems = visibleItems.filter((item): item is ActionGroupProps => Boolean(item));
  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState(validVisibleItems.length);

  const setObservedElement = useResizeObserver(
    useThrottledCallback(
      ([
        {
          contentRect: { width: containerWidth },
        },
      ]) => {
        let numberOfVisibleItems = 0;
        let remainingWidth = containerWidth;

        while (numberOfVisibleItems < validVisibleItems.length) {
          remainingWidth -= (validVisibleItems[numberOfVisibleItems].width ?? 0) + 8;

          if (remainingWidth < 0) {
            break;
          }

          numberOfVisibleItems++;
        }

        setNumberOfVisibleItems(numberOfVisibleItems);
      },
      150
    ),
    { box: 'border-box' }
  );

  const defaultRenderInToolbar = ({
    name,
    actions = [],
    onActionClick = () => console.log('...'),
  }: RequiredActionGroupProps) => (
    <div className='shrink-0 relative'>
      <ItemWithDropdown
        title={name}
        actionsData={actions}
      >
        <CustomButton
          text={
            <div className='flex items-center'>
              <span>{name}</span>
              <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' />
            </div>
          }
          type='button'
          outlined
          onClickBtn={onActionClick}
          btnStyle='transition-colors border-zinc-300 text-customLightBlue hover:text-amber-400'
        />
      </ItemWithDropdown>
    </div>
  );

  const defaultRenderInDropdown = ({
    name,
    actions = [],
    renderButtonContent,
  }: RequiredActionGroupProps & DropdownRenderProps) => {
    return (
      <ItemWithDropdown
        isNested
        title={name}
        actionsData={actions}
      >
        {renderButtonContent({
          name,
          icon: <ChevronLeftIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        })}
      </ItemWithDropdown>
    );
  };

  const itemsWithDefaults: RequiredActionGroupProps[] = validVisibleItems.map((item) => ({
    actions: [],
    renderInToolbar: defaultRenderInToolbar,
    renderInDropdown: defaultRenderInDropdown,
    width: 0,
    ...item,
  }));

  const itemsToRender = itemsWithDefaults.slice(itemsWithDefaults.length - numberOfVisibleItems);
  const itemsToHide = [
    ...itemsWithDefaults.slice(0, itemsWithDefaults.length - numberOfVisibleItems),
    ...hiddenItems.filter((item): item is ActionGroupProps => Boolean(item)),
  ];

  return (
    <div
      ref={setObservedElement}
      className={classNames('grow ml-auto max-w-full flex items-center justify-end space-x-2', className)}
      {...props}
    >
      {itemsToRender.map((item) => {
        return <Fragment key={item.id}>{item.renderInToolbar(item)}</Fragment>;
      })}

      {itemsToHide.length > 0 && (
        <ItemWithDropdown actionsData={itemsToHide}>
          <EllipsisVerticalIcon
            className='h-6 w-6'
            aria-hidden='true'
          />
        </ItemWithDropdown>
      )}
    </div>
  );
}

function ToolbarContainer({ className, children, ...props }: Props<{}, 'div'>) {
  return (
    <div
      className={classNames(
        'h-[44px] flex items-center mt-2 mb-[2px] pb-2 relative bottom-[0] border-b border-zinc-200',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

/* ********************** */
/* REUSABLE ITEMS */
/* ********************** */

type ChatSelectorItemProps = Pick<ChatSelectorProps, 'selected' | 'setSelected'>;

class ChatSelectorItem<T extends ItemOptions<ChatSelectorItemProps>> implements RequiredActionGroupProps {
  id: number;
  name = 'Ai Chat';
  actions: ActionProps[] = [];
  width = 200;

  protected props: ChatSelectorItemProps;

  constructor({ id, selected, setSelected }: T) {
    this.id = id;
    this.props = {
      selected,
      setSelected,
    };
  }

  renderInToolbar = () => {
    return <ChatSelector {...this.props} />;
  };

  renderInDropdown = ({ renderButtonContent }: RequiredActionGroupProps & DropdownRenderProps) => {
    return (
      <ChatSelector
        isStyled={false}
        anchor='left start'
        isNested
        {...this.props}
      >
        {renderButtonContent({
          name: this.name,
          icon: <ChevronLeftIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        })}
      </ChatSelector>
    );
  };
}

class ValidateItem implements ActionGroupProps {
  id: number;
  name = 'Validate';
  actions: ActionProps[];
  width = 105;

  constructor({ id, actions = [] }: ItemOptions) {
    this.id = id;
    this.actions = actions;
  }
}

class DocumentsSelectorItem<O extends Option> implements RequiredActionGroupProps {
  id: number;
  name: string;
  actions: ActionProps[] = [];
  width = 160;

  protected props: DocumentsSelectorProps<O>;

  constructor({ id, multiple, selected, setSelected, ...props }: ItemOptions<DocumentsSelectorProps<O>>) {
    this.id = id;
    this.name = multiple ? 'Select documents' : 'Select document';
    this.props = {
      multiple,
      selected,
      setSelected,
      title: this.name,
      ...props,
    } as DocumentsSelectorProps<O>;
  }

  renderInToolbar = () => {
    return <DocumentsSelector {...this.props} />;
  };

  renderInDropdown = ({ renderButtonContent }: RequiredActionGroupProps & DropdownRenderProps) => {
    return (
      <DocumentsSelector
        isStyled={false}
        anchor='left start'
        isNested
        {...this.props}
      >
        {renderButtonContent({
          name: this.name,
          icon: <ChevronLeftIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        })}
      </DocumentsSelector>
    );
  };
}

class ButtonItem implements ActionGroupProps {
  id: number;
  name: string;
  width: number;
  loading: boolean;
  iconComponent: FC<SVGProps<SVGSVGElement>>;
  onActionClick: VoidFunction;
  actions = [];

  constructor({
    id,
    name,
    width,
    iconComponent,
    loading = false,
    onActionClick,
  }: ItemOptions<{
    name: string;
    width: number;
    iconComponent: FC<SVGProps<SVGSVGElement>>;
    onActionClick: VoidFunction;
    loading?: boolean;
  }>) {
    this.id = id;
    this.name = name;
    this.width = width;
    this.loading = loading;
    this.iconComponent = iconComponent;
    this.onActionClick = onActionClick;
  }

  renderInToolbar = ({ name, onActionClick }: RequiredActionGroupProps) => {
    const Icon = this.iconComponent;

    return (
      <CustomButton
        text={name}
        type='button'
        outlined
        onClickBtn={onActionClick!}
        btnStyle='shrink-0 border-zinc-300 text-customLightBlue hover:text-amber-400'
        beforeIcon={<Icon className='h-4 mr-1' />}
        loading={this.loading}
      />
    );
  };

  renderInDropdown = ({ name, renderButtonContent }: RequiredActionGroupProps & DropdownRenderProps) => {
    const Icon = this.iconComponent;

    return renderButtonContent({
      name,
      icon: <Icon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    });
  };
}

function Toolbar({ className, children, ...props }: Props<{}, 'div'>) {
  return (
    <div
      className={classNames('grow flex mx-2', className)}
      {...props}
    >
      {children}
    </div>
  );
}

export default Object.assign(Toolbar, {
  Container: ToolbarContainer,
  Switch,
  Left: ToolbarLeft,
  Right: ToolbarRight,
  ChatSelectorItem,
  ValidateItem,
  DocumentsSelectorItem,
  ButtonItem
});
