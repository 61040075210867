import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";
import {
  getTemplateById,
  getPublicTemplateById,
} from "../../redux/templates/templatesApi";
import {
  getPathnameInSplitArray,
  getFirstPathFromUrl,
} from "../../utils/utilities";
import HtmlRenderer from "../../components/HTMLRenderer";
import { setTemplateSelectedDocument } from "../../redux/templates/templatesSlice";

const TemplatesDetails = () => {
  const [documentDetailLoader, setDocumentDetailLoader] = useState(false);
  const [templateDetail, setTemplateDetail] = useState<any>(null);

  // const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const {
    getPublicTemplateByIdRes,
    templateByIdRes,
    templateSelectedDocument,
  } = useAppSelector((state) => state.templates);

  const firstPath = getFirstPathFromUrl(window.location.pathname);

  useEffect(() => {
    gettingProjectTempDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstPath === "template") {
      setTemplateDetail(templateByIdRes);
    }
    if (firstPath === "community-template") {
      setTemplateDetail(getPublicTemplateByIdRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPublicTemplateByIdRes, templateByIdRes]);

  useEffect(() => {
    const urlSplit = getPathnameInSplitArray(window.location.pathname);
    const document_id = urlSplit ? urlSplit[4] : "";

    if (templateDetail && templateDetail?.document_templates?.length > 0) {
      const selectedDocumentItem = templateDetail?.document_templates.find(
        (item: { id: string }) => item.id === document_id
      );

      dispatch(setTemplateSelectedDocument(selectedDocumentItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDetail]);

  const gettingProjectTempDetail = () => {
    const fetchData = async () => {
      setDocumentDetailLoader(true);
      const urlSplit = getPathnameInSplitArray(window.location.pathname);
      const template_id = urlSplit ? urlSplit[2] : "";

      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (firstPath === "template") {
            await dispatch(
              getTemplateById({
                accessToken,
                id: template_id,
              })
            );
          }

          if (firstPath === "community-template") {
            await dispatch(
              getPublicTemplateById({
                accessToken,
                id: template_id,
              })
            );
          }

          setDocumentDetailLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDocumentDetailLoader(false);
      }
    };

    fetchData();
  };

  return (
    <WrapperLoader loading={documentDetailLoader}>
      <div className="w-full">
        <div className="p-4 mb-4 mx-4 border-b border-gray-100 text-right">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-sm font-semibold flex items-center text-customLightBlue mb-0">
              {templateSelectedDocument?.title}
            </h3>
          </div>
        </div>

        <div className="py-6 px-8">
          <HtmlRenderer htmlString={templateSelectedDocument?.content || ""} />
        </div>
      </div>
    </WrapperLoader>
  );
};

export default TemplatesDetails;
