import { AI_COMMANDS, DROPDOWN_TRANSITION_CLASSES } from 'src/config';
import {
    ChangeEvent,
    ComponentProps,
    FormEvent,
    KeyboardEvent,
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Transition, TransitionRootProps } from '@headlessui/react';
import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom';

import ArrowLeftIcon from '@heroicons/react/20/solid/ArrowLeftIcon';
import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon';
import CheckIcon from '@heroicons/react/20/solid/CheckIcon';
import PaperAirplaneIcon from '@heroicons/react/20/solid/PaperAirplaneIcon';
import Spinner from '../spinner';
import type { SuggestionsAPI } from 'src/pages/projectsDetails/textEditor/autoSuggestions';
import { classNames } from 'src/utils/utilities';

const NavButton = forwardRef<HTMLButtonElement, ComponentProps<'button'>>(
    ({ className, children, ...props }, forwardedRef) => {
        return (
            <button
                ref={forwardedRef}
                type='button'
                className={classNames(
                    'py-1 px-3 rounded-md border-[1px]',
                    'transition-colors enabled:hover:bg-amber-50 enabled:hover:border-amber-400',
                    'disabled:opacity-60 disabled:cursor-not-allowed',
                    className
                )}
                {...props}
            >
                {children}
            </button>
        );
    }
);

function TooltipHeader({
    suggestionsApi,
    suggestions,
    currentSuggestionIndex,
}: Readonly<{ suggestionsApi: SuggestionsAPI; suggestions: string[]; currentSuggestionIndex: number }>) {
    const handleAccept = useCallback(() => {
        suggestionsApi?.accept();
        suggestionsApi?.focusEditor();
    }, [suggestionsApi]);

    return (
        <div className='py-2 px-4 flex items-center justify-between bg-gray-50 rounded-t-lg border-b-[1px]'>
            <p className='text-sm font-medium text-gray-900'>
                Suggestion {currentSuggestionIndex + 1}/{suggestions.length}
            </p>
            <div className='flex items-center gap-2'>
                <NavButton
                    onClick={handleAccept}
                    className='bg-white text-sm text-gray-800'
                >
                    Accept
                </NavButton>
                <div className='group flex items-center bg-white text-gray-700'>
                    <NavButton
                        disabled={suggestions.length <= 1}
                        onClick={suggestionsApi.prev}
                        className='rounded-r-none group-hover:border-r-0 hover:!border-r-[1px]'
                    >
                        <ArrowLeftIcon className='h-5' />
                    </NavButton>
                    <NavButton
                        disabled={suggestions.length <= 1}
                        onClick={suggestionsApi.next}
                        className='rounded-l-none border-l-0 hover:border-l-[1px]'
                    >
                        <ArrowRightIcon className='h-5' />
                    </NavButton>
                </div>
            </div>
        </div>
    );
}

function InstructionsDropdown({
    options,
    selectedOptionId,
    onOptionChange,
    onOptionConfirmed,
    show,
    floatingRef,
    ...props
}: Readonly<{
    show: boolean;
    options: Array<{ id: number; label: string; instruction: string }>;
    onOptionChange: (id: number) => void;
    onOptionConfirmed: (id: number) => void;
    selectedOptionId: number;
    floatingRef?: (node: HTMLDivElement | null) => void;
} & TransitionRootProps<'div'>>) {
    return (
        <Transition
            ref={floatingRef}
            show={show}
            className={classNames(
                'mt-2 min-w-[70%] w-fit py-1 px-1',
                'rounded-md ring-1 ring-black ring-opacity-5 shadow-lg',
                'bg-white focus:outline-none'
            )}
            as='ul'
            role='listbox'
            {...DROPDOWN_TRANSITION_CLASSES}
            {...props}
        >
            {options.map(({ id, label }) => (
                <li
                    key={id}
                    role='option'
                    aria-selected={selectedOptionId === id}
                    onClick={() => onOptionConfirmed(id)}
                    className={classNames(
                        'w-full py-3 px-4 rounded-md',
                        'text-xs text-gray-700 transition-colors',
                        'flex items-center cursor-pointer',
                        selectedOptionId === id && 'bg-gray-100'
                    )}
                    onMouseEnter={() => onOptionChange(id)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onOptionConfirmed(id);
                        }
                    }}
                >
                    /{label}
                </li>
            ))}
        </Transition>
    );
}

function InstructionsInput({ suggestionsApi }: Readonly<{ suggestionsApi: SuggestionsAPI }>) {
    const submittedInstructionRef = useRef('');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [status, setStatus] = useState<'idle' | 'pending' | 'success'>('idle');
    const [instruction, setInstruction] = useState('');
    const [selectedOptionId, setSelectedOptionId] = useState(0);

    const { refs, floatingStyles } = useFloating({
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        middleware: [flip()],
    });

    const availableOptions = instruction.startsWith('/')
        ? AI_COMMANDS.filter(({ label }) => {
              return label.toLowerCase().startsWith(instruction.slice(1).toLowerCase());
          })
        : [];

    const handleConfirmOption = (confirmedOptionId: number) => {
        const selectedOption = availableOptions.find(({ id }) => id === confirmedOptionId);

        if (selectedOption) {
            setInstruction(selectedOption.label);
            setSelectedOptionId(confirmedOptionId);
            inputRef.current?.focus();
        }
    };

    const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (availableOptions.length === 0) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();

                setSelectedOptionId((curr) => {
                    const nextIndex = curr + 1;

                    if (nextIndex < availableOptions.length) {
                        return nextIndex;
                    }

                    return curr;
                });
                break;
            case 'ArrowUp':
                e.preventDefault();

                setSelectedOptionId((curr) => {
                    const nextIndex = curr - 1;

                    if (nextIndex >= 0) {
                        return nextIndex;
                    }

                    return curr;
                });
                break;
            case 'Enter':
                e.preventDefault();

                handleConfirmOption(selectedOptionId);
                break;
        }
    };

    const handleChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
        setInstruction(value);

        if (status === 'pending') {
            suggestionsApi.cancelPendingFetch();
        }

        if (status === 'pending' || (status === 'success' && value !== submittedInstructionRef.current)) {
            setStatus('idle');
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (instruction.trim() === '' || status !== 'idle') return;

        submittedInstructionRef.current = instruction;
        setStatus('pending');

        await suggestionsApi.refetch({ instructions: [instruction] });

        setStatus('success');
    };

    const buttonProps = ((): ComponentProps<'button'> => {
        switch (status) {
            case 'idle':
                return {
                    className: classNames(
                        'hover:-top-[1px] hover:-bottom-[1px] hover:-right-[1px]',
                        'transition-colors hover:border-amber-400 hover:bg-amber-50'
                    ),
                    children: <PaperAirplaneIcon className='w-5 text-gray-400' />,
                };
            case 'pending':
                return {
                    children: (
                        <Spinner
                            className='flex items-center'
                            svgClassName='w-5 h-5'
                        />
                    ),
                };
            case 'success':
                return {
                    children: <CheckIcon className='w-5 text-green-600' />,
                };
        }
    })();

    return (
        <>
            <form
                ref={refs.setReference}
                onSubmit={handleSubmit}
                className='relative rounded-md ring-inset ring-gray-300 ring-1 shadow-sm'
                autoComplete='off'
            >
                <input
                    ref={inputRef}
                    name='aiInstruction'
                    placeholder='Suggest changes or type / for commands'
                    className={classNames(
                        'block w-full py-[0.375rem] pl-3 pr-[52px] border-0 rounded-md',
                        'text-sm/[1.5rem] text-gray-900 ring-inset ring-gray-300 ring-1 shadow-sm'
                    )}
                    value={instruction}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                    autoComplete='off'
                    role='combobox'
                    aria-controls='instructions'
                    aria-expanded={availableOptions.length > 0}
                    aria-autocomplete='list'
                />
                <button
                    type='submit'
                    disabled={status !== 'idle' || instruction.trim() === ''}
                    tabIndex={-1}
                    className={classNames(
                        'absolute top-0 bottom-0 right-0',
                        'w-10 rounded-r-md border-[1px] border-transparent',
                        'flex items-center justify-center',
                        'disabled:cursor-default disabled:pointer-events-none',
                        buttonProps.className
                    )}
                >
                    {buttonProps.children}
                </button>
            </form>
            <InstructionsDropdown
                id="instructions"
                show={availableOptions.length > 0}
                options={availableOptions}
                selectedOptionId={selectedOptionId}
                onOptionChange={setSelectedOptionId}
                onOptionConfirmed={handleConfirmOption}
                floatingRef={refs.setFloating}
                style={floatingStyles}
            />
        </>
    );
}

function Kbd({ className = '', children, optional = false, ...props }: ComponentProps<'kbd'> & { optional?: boolean }) {
    return (
        <kbd
            className={classNames(
                'mx-1 min-w-5 h-5 px-1 rounded-[0.25rem] border-[1px] border-gray-400',
                'flex items-center justify-center bg-white font-semibold text-gray-900',
                optional && 'opacity-50',
                className
            )}
            {...props}
        >
            {children}
        </kbd>
    );
}

function TooltipFooter({ className }: Readonly<{ className?: string }>) {
    return (
        <div
            className={classNames(
                'px-4 py-[0.625rem] border-t-[1px] rounded-b-lg bg-gray-50',
                'flex flex-wrap items-center text-xs text-gray-700',
                className
            )}
        >
            <Kbd>Tab</Kbd> to accept,
            <Kbd>Ctrl</Kbd>{' '}
            <Kbd>
                <ArrowRightIcon className='h-4' />
            </Kbd>{' '}
            to accept a word,
            <Kbd>Esc</Kbd> to reject
        </div>
    );
}

export default function AiSuggestionsTooltip({
    className = '',
    suggestionsApi,
    ...props
}: ComponentProps<'div'> & { suggestionsApi: SuggestionsAPI | null }) {
    const [isLocked, setIsLocked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);

    const placeholderRef = useRef<HTMLElement | null>(null);

    const { refs, floatingStyles } = useFloating({
        placement: 'top-start',
        whileElementsMounted: autoUpdate,
        middleware: [shift({ padding: { right: 42 } }), flip()],
    });

    const showTooltip = suggestionsApi && suggestions.length > 0 && (isHovered || isLocked);

    const lockIn = useCallback(() => setIsLocked(true), []);
    const lockOut = useCallback(() => setIsLocked(false), []);

    const handleTabPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            suggestionsApi?.focusEditor();
        }
    };

    const handleMouseEnterOrLeave = useCallback(() => {
        const { current: reference } = placeholderRef;
        const {
            floating: { current: floating },
        } = refs;

        if (!reference) {
            return setIsHovered(false);
        }

        setIsHovered(reference.matches(':hover') || !!floating?.matches(':hover'));
    }, [refs]);

    const handleRef = (node: HTMLDivElement | null) => {
        const {
            floating: { current: currentNode },
        } = refs;

        if (currentNode === node) return;

        refs.setFloating(node);

        if (currentNode) {
            currentNode.removeEventListener('focusin', lockIn);
            currentNode.removeEventListener('focusout', lockOut);
        }

        if (node) {
            node.addEventListener('focusin', lockIn);
            node.addEventListener('focusout', lockOut);
        }
    };

    useEffect(() => {
        const unsubscribe = suggestionsApi?.onPlaceholder((type, { element, anchor }) => {
            if (type === 'enter') {
                refs.setReference(anchor);
                placeholderRef.current = element;
                element.addEventListener('mouseenter', handleMouseEnterOrLeave);
                element.addEventListener('mouseleave', handleMouseEnterOrLeave);
            } else if (type === 'exit') {
                refs.setReference(null);
                placeholderRef.current = null;
                element.removeEventListener('mouseenter', handleMouseEnterOrLeave);
                element.removeEventListener('mouseleave', handleMouseEnterOrLeave);
            }
        });

        return unsubscribe;
    }, [suggestionsApi, refs, handleMouseEnterOrLeave]);

    useEffect(() => {
        const unsubscribe = suggestionsApi?.onUpdate((suggestions, currentIndex) => {
            setSuggestions(suggestions);
            setCurrentSuggestionIndex(currentIndex);
        });

        return unsubscribe;
    }, [suggestionsApi]);

    useEffect(() => {
        if (!showTooltip) {
            setIsHovered(false);
            setIsLocked(false);
        }
    }, [showTooltip]);

    if (!showTooltip) return null;

    return (
        <div
            ref={handleRef}
            className={classNames(
                'max-w-[28rem] w-full flex flex-col rounded-lg border-[1px] z-50 shadow-lg',
                className
            )}
            style={floatingStyles}
            onMouseEnter={handleMouseEnterOrLeave}
            onMouseLeave={handleMouseEnterOrLeave}
            onKeyDown={handleTabPress}
            {...props}
        >
            <TooltipHeader
                suggestionsApi={suggestionsApi}
                suggestions={suggestions}
                currentSuggestionIndex={currentSuggestionIndex}
            />
            <div className='p-4 bg-white flex flex-col gap-4'>
                <p className='w-full text-sm text-gray-500 transition-colors duration-150 ease-in-out'>
                    {suggestions[currentSuggestionIndex]}
                </p>
                <InstructionsInput suggestionsApi={suggestionsApi} />
            </div>
            <TooltipFooter className='basis-full' />
        </div>
    );
}
