import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState } from "react";
import {setOpenNodeSetting, setSelectedNodeSetting} from '../../../redux/diagrams/diagramsSlice';

import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import { getRelativeNodesBounds } from "./utils";
import { useAppDispatch } from '../../../hooks';
import useDetachNodes from "./useDetachNodes";

const lineStyle = { borderColor: "white" };

function SimpleNode({ id, data }: any) {
  const [isEditText, setIsEditText] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const dispatch = useAppDispatch();

  const onDelete = () => deleteElements({ nodes: [{ id }] });
  const onDetach = () => detachNodes([id]);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<any>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const onOpenNodeEditSetting = () => {
    dispatch(
      setSelectedNodeSetting({
        id,
        data,
      })
    );
    dispatch(setOpenNodeSetting(true));
  };

  const handleResize = debounce(() => {
    // Any resize logic that might cause the ResizeObserver warning can be placed here.
  }, 100);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onEditNodeText = () => {
    setNodeTitle(data?.label);
    setIsEditText(!isEditText);
  };

  // Handle Enter key press
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        // Shift + Enter for a new line
        return; // Allow new line
      } else {
        // Only Enter pressed
        onUpdateNodes();
        setIsEditText(false); // Exit edit mode after pressing Enter
        event.preventDefault(); // Prevent default behavior of Enter key
      }
    }
  };

  const onUpdateNodes = () => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, label: nodeTitle || data?.label } }
          : node
      )
    );
  };

  const onChangeNodeTitle = (e: any) => {
    const value = e.target.value;
    setNodeTitle(value);
  };

  // Function to run when user clicks outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      textareaRef.current &&
      !textareaRef.current.contains(event.target as Node)
    ) {
      onUpdateNodes();
      setIsEditText(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconStyle = "h-4 m-1 cursor-pointer text-zinc-800";

  return (
    <div className="relative px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400 h-[100%]">
      <div className="text-center">
        <img src={data?.icon_url} alt="icon" className="h-14 mx-auto mb-3" />
        <div className="text-xs font-semibold cursor-text h-full" ref={nodeRef}>
          {isEditText ? (
            <textarea
              ref={textareaRef}
              autoFocus
              value={nodeTitle}
              onChange={onChangeNodeTitle}
              onKeyDown={handleKeyDown}
              className="p-0 pl-1 border-0 bg-white shadow-sm text-xs font-semibold text-center resize-none h-full w-full"
              style={{ boxShadow: "none", minHeight: 40 }}
            />
          ) : (
            <span onClick={() => onEditNodeText()}>{data?.label}</span>
          )}
        </div>
      </div>

      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true} // Always show the resizer handles
        handleStyle={{
          width: "12px", // Resizer handle size for easy grabbing
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar
        style={{
          borderRadius: 300,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag absolute top-0 left-0 bg-white px-2 flex items-center justify-center"
      >
        <TrashIcon
          data-tooltip-id="delete-node-tooltip"
          onClick={onDelete}
          className={`${iconStyle} hover:text-red-600`}
        />

        <PencilIcon
          data-tooltip-id="edit-node-tooltip"
          onClick={onOpenNodeEditSetting}
          className={`${iconStyle} hover:text-blue-600`}
        />

        {hasParent && (
          <ArrowTopRightOnSquareIcon
            data-tooltip-id="detach-node-tooltip"
            onClick={onDetach}
            className={`${iconStyle} hover:text-blue-600`}
          />
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(SimpleNode);