import React from 'react';
import {
  PlusIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { useAppSelector, useAppDispatch } from '../../../../../hooks';
import { setSelectedDocument } from '../../../../../redux/projects/projectsSlice';
import Toolbar, { ToolbarItemType } from 'src/components/toolbar';

interface DiagramActionsProps {
  setOpenGenerateModal?: Function;
  diagramSaveLoading: boolean;
  updatingProjectDiagram: Function;
}

export default function DiagramActions({
  setOpenGenerateModal,
  diagramSaveLoading,
  updatingProjectDiagram,
}: DiagramActionsProps) {
  const { selectedDocument } = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();

  const onSelectingDocument = (value: any) => {
    dispatch(setSelectedDocument(value));
  };

  const validateList = [
    {
      id: 1,
      name: '/Check for limitations',
      onActionClick: () => console.log('...'),
    },
    {
      id: 2,
      name: '/Validate Trafic Flow',
      onActionClick: () => console.log('...'),
    },
  ];

  const renderList: ToolbarItemType[] = [
    // new Toolbar.ButtonItem({
    //   id: 1,
    //   name: 'Regenerate',
    //   iconComponent: ArrowPathRoundedSquareIcon,
    //   onActionClick: () => console.log('Regenerate...'),
    //   width: 120,
    // }),
    new Toolbar.DocumentsSelectorItem({
      id: 2,
      selected: selectedDocument,
      setSelected: onSelectingDocument,
    }),
    new Toolbar.ValidateItem({
      id: 3,
      actions: validateList,
    }),
    setOpenGenerateModal && new Toolbar.ButtonItem({
      id: 4,
      name: 'Generate Flow Chart',
      iconComponent: PlusIcon,
      onActionClick: () => setOpenGenerateModal(),
      width: 175,
    }),
    // new Toolbar.ButtonItem({
    //   id: 5,
    //   name: 'Clear',
    //   onActionClick: () => console.log('Clear...'),
    //   iconComponent: TrashIcon,
    //   width: 85,
    // }),
    new Toolbar.ButtonItem({
      id: 6,
      name: 'Save Changes',
      onActionClick: () => updatingProjectDiagram(),
      loading: diagramSaveLoading,
      iconComponent: ArrowDownTrayIcon,
      width: 135,
    }),
  ];

  return (
    <Toolbar.Container>
      <Toolbar>
        <Toolbar.Right visibleItems={renderList} />
      </Toolbar>
    </Toolbar.Container>
  );
}
