import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomButton from '../../../components/customButton';
import TextAreaField from '../../../components/formFields/textareaField';
import SelectField from '../../../components/formFields/selectField';
import InputField from '../../../components/formFields/inputField';
import { categoriesList } from '../../../utils/utilities';
import RadioGroup from '../../../components/formFields/radioGroup';
import { DRAWER_WIDTH } from 'src/config';

interface SaveProjectAsTemplateProps {
  onCloseModal: () => void;
  onSubmitForm: Function;
  loader: boolean;
  projectData: any;
}

export default function SaveProjectAsTemplate({
  onCloseModal,
  onSubmitForm,
  loader,
  projectData,
}: SaveProjectAsTemplateProps) {
  const [selectedComplexity, setSelectedComplexity] = useState('low');

  const validationObject = {
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: projectData?.title || '',
      description: projectData?.description || '',
      category: projectData?.category || 'Personal',
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        complexity: selectedComplexity,
        category: values?.category,
        description: values?.description,
      };

      onSubmitForm(payloadObj);
    },
  });

  const complexityList = [
    { id: 1, value: 'low', title: 'Low' },
    { id: 2, value: 'medium', title: 'Medium' },
    { id: 3, value: 'complex', title: 'Complex' },
  ];

  const eachFieldRow =
    'items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200';

  useEffect(() => {
    if (projectData?.complexity) {
      setSelectedComplexity(projectData?.complexity);
    }
  }, [projectData]);

  return (
    <form
      className='flex flex-col bg-white shadow-xl'
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className='overflow-y-scroll'
        style={{
          height: 'calc(100vh - 164px)',
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div className='flex items-start'>
            <label
              htmlFor='title'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Title
            </label>
          </div>
          <div className='sm:col-span-2'>
            <InputField
              type='text'
              id='title'
              {...formik.getFieldProps('title')}
              placeholder='Enter title'
            />

            {formik.touched.title && formik.errors.title ? (
              <div className='text-red-600 text-xs'>{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Complexity */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <label
              htmlFor='complexity'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Complexity
            </label>
          </div>

          <div className='sm:col-span-2'>
            <RadioGroup
              onChangeRadio={setSelectedComplexity}
              value={selectedComplexity}
              radioList={complexityList}
            />

            {formik.touched.complexity && formik.errors.complexity ? (
              <div className='text-red-600 text-xs'>{formik.errors.complexity}</div>
            ) : null}
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor='category'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Category
            </label>
          </div>
          <div className='sm:col-span-2'>
            <SelectField
              options={categoriesList}
              id='category'
              {...formik.getFieldProps('category')}
            />

            {formik.touched.category && formik.errors.category ? (
              <div className='text-red-600 text-xs'>{formik.errors.category}</div>
            ) : null}
          </div>
        </div>

        {/* Description */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <label
              htmlFor='description'
              className='block text-xs font-medium leading-6 text-customDarkBlue'
            >
              Description
            </label>
          </div>
          <div className='sm:col-span-2'>
            <TextAreaField
              id='description'
              {...formik.getFieldProps('description')}
              placeholder='Enter a brief description.'
            />

            {formik.touched.description && formik.errors.description ? (
              <div className='text-red-600 text-xs'>{formik.errors.description}</div>
            ) : null}
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div
        className='fixed bg-white bottom-0 border-t border-gray-200 px-4 py-5 sm:px-6'
        style={{ width: `${DRAWER_WIDTH - 40}px` }}
      >
        <div className='flex justify-end space-x-3'>
          <button
            type='button'
            className='rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            onClick={onCloseModal}
          >
            Cancel
          </button>
          <CustomButton
            type='submit'
            onClickBtn={() => console.log('Clicking...')}
            text='Save'
            loading={loader}
          />
        </div>
      </div>
    </form>
  );
}
