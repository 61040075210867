import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ChevronDownIcon,
  ArrowUpRightIcon,
  ArrowUturnDownIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";
import {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  MarkerType,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesDelete,
  OnNodeDrag,
  OnNodesChange,
  OnNodesDelete,
  Panel,
  ProOptions,
  ReactFlow,
  SelectionDragHandler,
  addEdge,
  applyNodeChanges,
  reconnectEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import {
  CircleAnimatedEdge,
  ImageOnEdge,
} from "./customeAnimatedNode/AnimatedEdge";
import {
  DragEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import {
  colors,
  edgeStyles,
  edgeTypesList,
  getId,
  getNodePositionInsideParent,
  sortNodes,
} from "./utils";
import {
  edges as initialEdges,
  nodes as initialNodes,
} from "./initial-elements";
import {
  setOpenNodeSetting,
  setSelectedNodeSetting,
} from "../../../redux/diagrams/diagramsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import CustomDrawer from "../../../components/customDrawer";
import EdgeIconsDropdown from "./EdgeIconsDropdown";
import GroupNode from "./GroupNode";
import HelperLines from "./HelperLines";
import SelectedNodesToolbar from "./SelectedNodesToolbar";
import Sidebar from "./Sidebar";
import SimpleNode from "./SimpleNode";
import { getHelperLines } from "./utils";
import styles from "./style.module.css";
import SwitchSlide from "../../../components/switchSlide";
import useCopyPaste from "./useCopyPaste";
import useUndoRedo from "./useUndoRedo";
import CustomButton from "../../../components/customButton";

import "@xyflow/react/dist/style.css";
import "@reactflow/node-resizer/dist/style.css";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

const nodeTypes = {
  node: SimpleNode,
  group: GroupNode,
};

const defaultEdgeOptions = {
  style: {
    strokeWidth: 2,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};

// Animated Edge Types
const edgeTypes = {
  circleAnimatedEdge: CircleAnimatedEdge,
  imageOnEdge: ImageOnEdge,
};

interface FlowChartProps {
  diagram: string;
}

function DynamicGrouping({ diagram }: FlowChartProps) {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined);

  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined);
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [selectedEdgeColor, setSelectedEdgeColor] = useState(colors[0]);
  const [selectedEdgeBorder, setSelectedEdgeBorder] = useState("2");
  const [selectedEdgeType, setSelectedEdgeType] = useState("bezier");
  const [isEdgeAnimated, setIsEdgeAnimated] = useState(true);
  const [animatedCircleColor, setAnimatedCircleColor] = useState("");
  const [edgeImage, setEdgeImage] = useState("");
  const [connectorLayout, setConnectorLayout] = useState("");

  const { selectedDiagram, selectedNodeSetting, openNodeSetting } =
    useAppSelector((state) => state.diagrams);
  const {
    screenToFlowPosition,
    getIntersectingNodes,
    getNodes,
    fitView,
  } = useReactFlow();
  const dispatch = useAppDispatch();
  useCopyPaste();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();

  const edgeReconnectSuccessful = useRef(true);

  useEffect(() => {
    const { nodes, edges } = selectedDiagram?.design
      ? JSON.parse(selectedDiagram?.design)
      : { nodes: null, edges: null };
    setNodes(nodes || []);
    setEdges(edges || []);

    // Run fitView after nodes and edges are set
    setTimeout(() => {
      fitView({ padding: 0.1, duration: 800 });
    }, 850);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiagram]);

  useEffect(() => {
    if (diagram) {
      try {
        let jsonString = diagram || "";

        // Remove the single quotes from the start and end
        let cleanedJsonString = jsonString.slice(1, -1);

        // Regular expressions to extract initialNodes and initialEdges
        let nodesRegex = /initialNodes\s*=\s*(\[[\s\S]*?\]);/;
        let edgesRegex = /initialEdges\s*=\s*(\[[\s\S]*?\]);/;

        // Extract and convert initialNodes to JSON string
        let initialNodesMatch = cleanedJsonString.match(nodesRegex);
        let initialNodesString = initialNodesMatch
          ? toJsonString(initialNodesMatch[1])
          : "[]";
        let initialNodes = JSON.parse(initialNodesString);

        // Extract and convert initialEdges to JSON string
        let initialEdgesMatch = cleanedJsonString.match(edgesRegex);
        let initialEdgesString = initialEdgesMatch
          ? toJsonString(initialEdgesMatch[1])
          : "[]";
        let initialEdges = JSON.parse(initialEdgesString);

        setNodes(initialNodes);
        setEdges(initialEdges);

        // Run fitView after nodes and edges are set
        setTimeout(() => {
          fitView({ padding: 0.1, duration: 800 });
        }, 0);
      } catch {
        console.log("Error: Please try again letter");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagram]);

  useEffect(() => {
    if (selectedEdge) {
      if (selectedEdge?.style) {
        setSelectedEdgeColor(`${selectedEdge?.style?.stroke}`);
        setSelectedEdgeBorder(`${selectedEdge?.style?.strokeWidth}`);
        setConnectorLayout(`${selectedEdge?.style?.strokeDasharray}`);
      }

      if (
        selectedEdge?.type !== "circleAnimatedEdge" &&
        selectedEdge?.type !== "imageOnEdge"
      ) {
        setSelectedEdgeType(`${selectedEdge?.type}`);
      }

      if (selectedEdge?.type !== "circleAnimatedEdge") {
        setAnimatedCircleColor("");
      }

      if (selectedEdge?.type !== "imageOnEdge") {
        setEdgeImage("");
      }

      if (selectedEdge?.type === "circleAnimatedEdge" && selectedEdge?.data) {
        setEdgeImage("");
        setAnimatedCircleColor(`${selectedEdge?.data?.color}`);
        setSelectedEdgeType(`${selectedEdge?.data?.edgeType}`);
      }

      if (selectedEdge?.type === "imageOnEdge" && selectedEdge?.data) {
        setAnimatedCircleColor("");
        setEdgeImage(`${selectedEdge?.data?.imgUrl}`);
        setSelectedEdgeType(`${selectedEdge?.data?.edgeType}`);
      }

      setIsEdgeAnimated(selectedEdge?.animated || false);
    }
  }, [selectedEdge]);

  // Function to convert JavaScript-like object strings to JSON strings
  function toJsonString(jsObjectString: any) {
    return jsObjectString
      .replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":') // Add quotes around property names
      .replace(/'/g, '"'); // Replace single quotes with double quotes
  }

  const onConnect: OnConnect = useCallback(
    (edge: Edge | Connection) => {
      // 👇 make adding edges undoable
      takeSnapshot();

      const newEdge = {
        ...edge,
        animated: true,
        type: "bezier",
        style: { strokeWidth: 2, strokeDasharray: "5,5", stroke: "#b1b1b7" },
      };

      setEdges((eds) => addEdge(newEdge, eds));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEdges]
  );

  const onDrop = (event: DragEvent) => {
    event.preventDefault();

    const type = event.dataTransfer.getData("application/reactflow");
    const iconUrl = event.dataTransfer.getData("iconUrl/reactflow");
    const name = event.dataTransfer.getData("name/reactflow");

    // Style for the group
    const style = event.dataTransfer.getData("style/reactflow");
    const styleParse = style ? JSON.parse(style) : null;

    const position = screenToFlowPosition({
      x: event.clientX - 20,
      y: event.clientY - 20,
    });
    const nodeDimensions =
      type === "group" ? { width: 400, height: 400 } : { width: 120 };

    const intersections = getIntersectingNodes({
      x: position.x,
      y: position.y,
      width: 40,
      height: 40,
    }).filter((n) => n.type === "group");
    const groupNode = intersections[0];

    const newNode: Node = {
      id: getId(),
      type,
      position,
      data: {
        label: `${name}`,
        icon_url: iconUrl,
        border_style: type === "group" ? styleParse?.border_style || "" : "",
        color: type === "group" ? styleParse?.color || "" : "",
      },
      ...nodeDimensions,
    };

    if (groupNode) {
      // if we drop a node on a group node, we want to position the node inside the group
      newNode.position = getNodePositionInsideParent(
        {
          position,
          width: 40,
          height: 40,
        },
        groupNode
      ) ?? { x: 0, y: 0 };
      newNode.parentId = groupNode?.id;
      newNode.expandParent = true;
    }

    // we need to make sure that the parents are sorted before the children
    // to make sure that the children are rendered on top of the parents
    const sortedNodes = getNodes().concat(newNode).sort(sortNodes);
    setNodes(sortedNodes);
  };

  const onNodeDragStop = useCallback(
    (_: MouseEvent, node: Node) => {
      // make dragging a node undoable
      takeSnapshot();

      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupNode = intersections[0];

      // when there is an intersection on drag stop, we want to attach the node to its new parent
      if (intersections.length && node.parentId !== groupNode?.id) {
        const nextNodes: Node[] = getNodes()
          .map((n) => {
            if (n.id === groupNode.id) {
              return {
                ...n,
                className: "",
              };
            } else if (n.id === node.id) {
              const position = getNodePositionInsideParent(n, groupNode) ?? {
                x: 0,
                y: 0,
              };

              return {
                ...n,
                position,
                parentId: groupNode.id,
                extent: "parent",
              } as Node;
            }

            return n;
          })
          .sort(sortNodes);

        setNodes(nextNodes);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIntersectingNodes, getNodes, setNodes]
  );

  const onNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupClassName =
        intersections.length && node.parentId !== intersections[0]?.id
          ? "active"
          : "";

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.type === "group") {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }

          return { ...n };
        });
      });
    },
    [getIntersectingNodes, setNodes]
  );

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge: any, newConnection: any) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReconnectEnd = useCallback((_: any, edge: any) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeReconnectSuccessful.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === "position" &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, nodes);
    },
    []
  );

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) => customApplyNodeChanges(changes, nodes));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, customApplyNodeChanges]
  );

  const onCloseNodeEditSetting = () => {
    dispatch(setOpenNodeSetting(false));
    dispatch(setSelectedNodeSetting(null));
  };

  const onNodeDragStart: OnNodeDrag = useCallback(() => {
    // make dragging a node undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    // make dragging a selection undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onNodesDelete: OnNodesDelete = useCallback(() => {
    // make deleting nodes undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onEdgesDelete: OnEdgesDelete = useCallback(() => {
    // make deleting edges undoable
    takeSnapshot();

    onResetFormattingState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takeSnapshot]);

  // Select an edge when clicked
  const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
    event.stopPropagation();
    setSelectedEdge(edge);
  }, []);

  // Select an node when clicked
  const onNodeClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onResetFormattingState();
  }, []);

  // Handle edge style change
  const handleStyleChange = (style: React.CSSProperties) => {
    if (selectedEdge) {
      setConnectorLayout(`${style.strokeDasharray}`);

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                style: {
                  ...edge.style,
                  strokeDasharray: style?.strokeDasharray,
                },
              } // Apply new style to the selected edge
            : edge
        )
      );
    }
  };

  console.log("selectedEdge =========", selectedEdge);

  // Handle edge type change
  const onChangeEdgeType = (edgeObj: any) => {
    setSelectedEdgeType(`${edgeObj.type}`);

    if (!selectedEdge) return;

    setEdges((edges) =>
      edges.map((edge) => {
        // Check if the current edge is the selected one
        if (edge.id === selectedEdge.id) {
          // Check if the selected edge is animated
          if (
            selectedEdge.type === "circleAnimatedEdge" ||
            selectedEdge.type === "imageOnEdge"
          ) {
            return {
              ...edge,
              data: { ...edge.data, edgeType: edgeObj.type }, // Set edgeType in data for animated edges
            };
          }

          // For non-animated edges, update the type directly
          return {
            ...edge,
            type: edgeObj.type,
          };
        }

        // Return edge unchanged if it's not the selected one
        return edge;
      })
    );
  };

  // Handle edge animation change with color
  const addObjectChange = (edgeObj: any) => {
    if (selectedEdge) {
      setEdgeImage("");
      setAnimatedCircleColor(`${edgeObj?.color}`);
      const getEdgeItem = edges?.find((data) => data.id === selectedEdge.id);

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                type: "circleAnimatedEdge",
                data: {
                  ...edge.data,
                  edgeType: selectedEdgeType,
                  color: edgeObj?.color,
                },
              } // Apply new type to the selected edge
            : edge
        )
      );

      if (getEdgeItem) {
        setSelectedEdge({
          ...getEdgeItem,
          type: "circleAnimatedEdge",
          data: {
            ...getEdgeItem.data,
            edgeType: selectedEdgeType,
            color: edgeObj?.color,
          },
        });
      }
    }
  };

  // Handle edge image change
  const onChangeEdgeImage = (edgeObj: any) => {
    if (selectedEdge) {
      setAnimatedCircleColor("");
      setEdgeImage(`${edgeObj?.url}`);

      const getEdgeItem = edges?.find((data) => data.id === selectedEdge.id);

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                type: "imageOnEdge",
                data: {
                  ...edge.data,
                  edgeType: selectedEdgeType,
                  imgUrl: edgeObj?.url,
                  label: edgeObj?.name,
                },
              } // Apply new type to the selected edge
            : edge
        )
      );

      if (getEdgeItem) {
        setSelectedEdge({
          ...getEdgeItem,
          type: "imageOnEdge",
          data: {
            ...getEdgeItem.data,
            edgeType: selectedEdgeType,
            imgUrl: edgeObj?.url,
            label: edgeObj?.name,
          },
        });
      }
    }
  };

  // Function to change stroke width
  const changeEdgeStrokeWidth = (
    newStrokeWidth: number,
    selectedEdge: Edge | null,
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
  ) => {
    setSelectedEdgeBorder(`${newStrokeWidth}`);

    if (!selectedEdge) return; // Ensure there's a selected edge

    setEdges((edges) =>
      edges.map((edge) =>
        edge.id === selectedEdge.id
          ? { ...edge, style: { ...edge.style, strokeWidth: newStrokeWidth } }
          : edge
      )
    );
  };

  // Function to change edge color
  const changeEdgeColor = (
    newColor: string,
    selectedEdge: Edge | null,
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
  ) => {
    if (!selectedEdge) return; // Ensure there's a selected edge

    setEdges((edges) =>
      edges.map((edge) =>
        edge.id === selectedEdge.id
          ? { ...edge, style: { ...edge.style, stroke: newColor } }
          : edge
      )
    );
  };

  // on Enable Edge Animation
  const onChangeEdgeAnimation = (isAnimated: any) => {
    setIsEdgeAnimated(isAnimated);
    if (!selectedEdge) return; // Ensure there's a selected edge

    setEdges((edges) =>
      edges.map((edge) =>
        edge.id === selectedEdge.id ? { ...edge, animated: isAnimated } : edge
      )
    );
  };

  const handleStrokeWidthChange = (width: string) => {
    const numberWidth = width ? parseInt(width) : 2;
    changeEdgeStrokeWidth(numberWidth, selectedEdge, setEdges);
  };

  const handleColorChange = (color: string) => {
    setSelectedEdgeColor(color);
    changeEdgeColor(color, selectedEdge, setEdges);
  };

  // Clear Connection All Formatting
  const onClearAllFormatting = () => {
    if (selectedEdge) {
      setEdgeImage("");
      setAnimatedCircleColor("");

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                style: {
                  strokeWidth: 2,
                  strokeDasharray: "5,5",
                  stroke: "#b1b1b7",
                },
                type: "bezier",
                animated: true,
              }
            : edge
        )
      );
    }
  };

  // On Edge Delete Clear Connection All Formatting
  const onResetFormattingState = () => {
    setSelectedEdge(null);
    setEdgeImage("");
    setAnimatedCircleColor("");
    setSelectedEdgeColor(colors[0]);
    setSelectedEdgeBorder("2");
    setSelectedEdgeType("bezier");
    setIsEdgeAnimated(true);
    setConnectorLayout("");
  };

  // Panel Button style
  const panelBtnStyle =
    "py-1 px-2 text-zinc-900 border-r border-zinc-100 bg-white hover:bg-zinc-100";
  const panelIconStyle = "h-[13px] text-zinc-900";

  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.rfWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onDrop={onDrop}
          onDragOver={onDragOver}
          proOptions={proOptions}
          selectNodesOnDrag={false}
          nodeTypes={nodeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          onReconnect={onReconnect}
          onReconnectStart={onReconnectStart}
          onReconnectEnd={onReconnectEnd}
          onNodeDragStart={onNodeDragStart}
          onSelectionDragStart={onSelectionDragStart}
          onNodesDelete={onNodesDelete}
          onEdgesDelete={onEdgesDelete}
          onEdgeClick={onEdgeClick}
          onNodeClick={onNodeClick}
          edgeTypes={edgeTypes}
        >
          <Controls />
          <Panel className="top-0 left-0 m-3 p-0 bg-white border border-zinc-100 text-sm shadow-sm">
            <div className="flex items-center">
              <button
                className={panelBtnStyle}
                disabled={canUndo}
                onClick={undo}
                title="Undo"
              >
                <ArrowUturnLeftIcon className={panelIconStyle} />
              </button>
              <button
                className={panelBtnStyle}
                disabled={canRedo}
                onClick={redo}
                title="Redo"
              >
                <ArrowUturnRightIcon className={panelIconStyle} />
              </button>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton
                    disabled={!selectedEdge}
                    className={`flex items-center text-xs ${panelBtnStyle} ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                  >
                    <svg height="10" width="50">
                      <line
                        x1="0"
                        y1="5"
                        x2="50"
                        y2="5"
                        stroke="#333333"
                        strokeWidth={2}
                        strokeDasharray={connectorLayout || "none"}
                      />
                    </svg>
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="ml-2 h-4 w-4 text-gray-600"
                    />
                  </MenuButton>
                </div>

                <MenuItems className="absolute left-0 z-10 mt-2 p-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  {/* UI for the list of edge styles */}

                  <div>
                    <div className="text-xs mb-2 text-zinc-500">
                      Connector Style
                    </div>
                    <div className="grid grid-cols-3 gap-2 mb-2">
                      {edgeStyles.map((edgeStyle, index) => (
                        <button
                          key={index}
                          title={edgeStyle.label}
                          className={`p-2 flex ${connectorLayout === edgeStyle.style.strokeDasharray ? "bg-gray-200" : ""} hover:bg-gray-200 transition text-xs`}
                          onClick={() => handleStyleChange(edgeStyle.style)}
                        >
                          {/* Show a visual representation of the edge style */}
                          <svg height="10" width="50">
                            <line
                              x1="0"
                              y1="5"
                              x2="50"
                              y2="5"
                              stroke={edgeStyle.style.stroke}
                              strokeWidth={edgeStyle.style.strokeWidth}
                              strokeDasharray={
                                edgeStyle.style.strokeDasharray || "none"
                              }
                            />
                          </svg>
                        </button>
                      ))}
                    </div>

                    <div className="text-xs mb-2 text-zinc-500">Animated</div>

                    <div>
                      <SwitchSlide
                        setEnabled={() =>
                          onChangeEdgeAnimation(!isEdgeAnimated)
                        }
                        enabled={isEdgeAnimated}
                      />
                    </div>
                  </div>
                </MenuItems>
              </Menu>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton
                    className={`flex items-center text-xs ${panelBtnStyle} ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                    disabled={!selectedEdge}
                  >
                    <div className="text-xs text-zinc-900 flex">
                      {selectedEdgeType === "straight" && (
                        <ArrowUpRightIcon className="mr-2 h-4" />
                      )}
                      {selectedEdgeType === "smoothstep" && (
                        <ArrowUturnRightIcon className="mr-2 h-4" />
                      )}
                      {selectedEdgeType === "step" && (
                        <ArrowUturnDownIcon className="mr-2 h-4" />
                      )}
                      {selectedEdgeType === "bezier" && (
                        <ArrowTrendingDownIcon className="mr-2 h-4" />
                      )}
                      <span className="capitalize">{selectedEdgeType}</span>
                    </div>
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="ml-2 h-4 w-4 text-gray-600"
                    />
                  </MenuButton>
                </div>

                <MenuItems className="absolute left-0 z-10 mt-2 p-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  <div className="mb-2">
                    {edgeTypesList.map((edgeType, index) => (
                      <button
                        key={index}
                        title={edgeType.label}
                        className={`p-2 w-full rounded-sm ${selectedEdgeType === edgeType.type ? "bg-gray-100" : ""} hover:bg-gray-100 transition text-xs mb-1 w-full`}
                        onClick={() => onChangeEdgeType(edgeType)} // Handle edge type selection
                      >
                        <div className="text-xs text-zinc-900 flex">
                          {edgeType?.type === "straight" && (
                            <ArrowUpRightIcon className="mr-2 h-4" />
                          )}
                          {edgeType?.type === "smoothstep" && (
                            <ArrowUturnRightIcon className="mr-2 h-4" />
                          )}
                          {edgeType?.type === "step" && (
                            <ArrowUturnDownIcon className="mr-2 h-4" />
                          )}
                          {edgeType?.type === "bezier" && (
                            <ArrowTrendingDownIcon className="mr-2 h-4" />
                          )}
                          <span>{edgeType?.label}</span>
                        </div>
                      </button>
                    ))}
                  </div>
                </MenuItems>
              </Menu>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton
                    className={`flex items-center text-xs ${panelBtnStyle} ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                    disabled={!selectedEdge}
                  >
                    {edgeImage && (
                      <img
                        className="h-[17px]"
                        src={edgeImage}
                        alt="Selected Icon"
                      />
                    )}
                    {animatedCircleColor && (
                      <button
                        className={`w-[13px] h-[13px] rounded-full p-1 scale-125 border-2 border-zinc-100 hover:scale-125`}
                        style={{
                          background: animatedCircleColor,
                          outline: `solid ${animatedCircleColor} 1px`,
                        }}
                      />
                    )}

                    {!edgeImage && !animatedCircleColor && "Icon"}
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="ml-2 h-4 w-4 text-gray-600"
                    />
                  </MenuButton>
                </div>

                <MenuItems className="absolute left-[-100px] z-10 mt-2 p-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  <EdgeIconsDropdown
                    onChangeEdgeImage={onChangeEdgeImage}
                    addObjectChange={addObjectChange}
                    animatedCircleColor={animatedCircleColor}
                    edgeImage={edgeImage}
                  />
                </MenuItems>
              </Menu>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <MenuButton
                    className={`flex items-center ${panelBtnStyle} ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                    disabled={!selectedEdge}
                  >
                    <div
                      style={{ backgroundColor: selectedEdgeColor }}
                      className="w-4 h-4 rounded-sm"
                    />

                    <ChevronDownIcon
                      aria-hidden="true"
                      className="ml-2 h-4 w-4 text-gray-600"
                    />
                  </MenuButton>
                </div>

                <MenuItems className="absolute left-0 z-10 mt-2 p-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  {colors.map((color) => (
                    <button
                      key={color}
                      style={{ backgroundColor: color }}
                      className="mr-2 w-6 h-6 rounded-sm transition-all hover:scale-125"
                      onClick={() => handleColorChange(color)}
                    />
                  ))}
                </MenuItems>
              </Menu>

              <div>
                <select
                  id="stroke-width"
                  name="stroke-width"
                  value={selectedEdgeBorder}
                  onChange={(e) => handleStrokeWidthChange(e.target.value)}
                  className={`border-0 py-0 text-xs ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                  style={{ boxShadow: "none" }}
                  disabled={!selectedEdge}
                >
                  <option value="1">1 px</option>
                  <option value="2">2 px</option>
                  <option value="3">3 px</option>
                  <option value="4">4 px</option>
                  <option value="5">5 px</option>
                  <option value="6">6 px</option>
                  <option value="7">7 px</option>
                  <option value="8">8 px</option>
                  <option value="9">9 px</option>
                  <option value="10">10 px</option>
                </select>
              </div>

              {selectedEdge && (
                <div>
                  <CustomButton
                    text="Clear Formatting"
                    onClickBtn={() => onClearAllFormatting()}
                    type={"button"}
                    outlined
                    btnStyle="text-red-600 text-xs border-0"
                  />
                </div>
              )}

              {/* {edgeStyles?.map((edgeStyle, index) => {
                return (
                  <button
                    key={index}
                    className={panelBtnStyle}
                    onClick={() => handleStyleChange(edgeStyle.style)}
                    title={edgeStyle.label}
                  >
                    <ArrowRightIcon className={panelIconStyle} />
                  </button>
                );
              })} */}
            </div>
          </Panel>

          <Background
            color="#464646"
            gap={50}
            variant={BackgroundVariant.Dots}
          />
          <SelectedNodesToolbar />
          <HelperLines
            horizontal={helperLineHorizontal}
            vertical={helperLineVertical}
          />
        </ReactFlow>
      </div>

      <CustomDrawer
        open={openNodeSetting}
        onCloseModal={onCloseNodeEditSetting}
        title="Node Setting"
        description="Here is your node setting"
      >
        <div className="h-screen bg-white p-6">
          <h3 className="font-semibold text-customDarkBlue">
            {selectedNodeSetting?.data?.label || ""}
          </h3>
        </div>
      </CustomDrawer>
    </div>
  );
}

export default function Flow({ diagram }: FlowChartProps) {
  return <DynamicGrouping diagram={diagram} />;
}
