import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProfileData } from "../../redux/profile/profileApi";

import { createTbar, getTbarsList } from "../../redux/tbars/tbarsApi";
import CustomDrawer from "../../components/customDrawer";
import TBarForm from "../../pages/T-bar/TBarForm";
import {
  createProsCons,
  getProsConsList,
} from "../../redux/prosCons/prosConsApi";
import ProConCreationForm from "../../pages/prosCons/CreationForm";
import {
  createDecisionMatrix,
  getDecisionMatrixList,
} from "../../redux/decisionMatrix/decisionMatrixApi";
import CreationDecisionMatrixForm from "../../pages/decisionMatrix/CreationForm";
import { createSwot, getSwotList } from "../../redux/swot/swotApi";
import {
  createProject,
  getProjectsList,
} from "../../redux/projects/projectsApi";
import CreationSWOTForm from "../../pages/swot/CreationForm";
import CreateProjectForm from "../../pages/projects/CreationForm";
import { setCommunityPageTabs } from "../../redux/userAiAssistants/userAiAssistantsSlice";
import { createUserAiAssistant } from "../../redux/userAiAssistants/userAiAssistantsApi";
import CreateAiAisstantForm from "../../pages/aiAssistants/CreationForm";
import { classNames } from "../../utils/utilities";
import DropdownSelectionOption from "../dropdownSelectionOption";
import { setSelectedDocument } from "../../redux/projects/projectsSlice";
import { setSelectedDiagram } from "../../redux/diagrams/diagramsSlice";
import CustomModal from "../customModal";
import DocumentForm from "../../pages/projectsDetails/leftSideBar/documents/documentForm";
import { createProjectDocument } from "../../redux/projects/projectsApi";
import { getLastIdFromUrl, getFirstPathFromUrl } from "../../utils/utilities";
import LoginUserInfo from "../loginUserInfo";
import DiagramForm from "../../pages/projectsDetails/leftSideBar/diagrams/diagramForm";
import { createDiagram } from "../../redux/diagrams/diagramsApi";
import { setTemplateSelectedDocument } from "../../redux/templates/templatesSlice";

import mainLogo from "../../assets/images/solution_pilot.svg";
import { setSelectedTChart } from "src/redux/tbars/tbarsSlice";
import { setSelectedProsCons } from "src/redux/prosCons/prosConsSlice";
import { setSelectedDecisionMatrix } from "src/redux/decisionMatrix/decisionMatrixSlice";
import { setSelectedSwot } from "src/redux/swot/swotSlice";
import { tChartIcon } from "src/assets/icons";

// const aiAssistantsTabsList: any = [
//   { id: 1, name: 'Community' },
//   { id: 2, name: 'My Assistants' },
// ];

const communityPageTabsList: any = [
  { id: 1, name: "Designs & Documents" }, // A place where users can share their designs and documents
  { id: 2, name: "AI Prompts" }, // A place where users can share their AI prompts
  { id: 3, name: "Jobs" }, // A job board. If companies are looking for an architect, this is where they will find them.
  { id: 4, name: "Network" }, // Other users profiles
];

interface MainHeaderProps {
  setSidebarOpen: Function;
  collapseSidebar: boolean;
}

interface LinkProps {
  disabled?: boolean;
}

function HomeLink({ disabled = false }: Readonly<LinkProps>) {
  const navigate = useNavigate();

  return (
    <li>
      <div
        onClick={() => !disabled && navigate("/home")}
        className={disabled ? "" : "cursor-pointer"}
      >
        <HomeIcon
          className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700"
          aria-hidden="true"
        />
      </div>
    </li>
  );
}

function Divider() {
  return (
    <svg
      className="h-4 w-4 flex-shrink-0 text-gray-300"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
    </svg>
  );
}

function Text({ children }: Readonly<PropsWithChildren>) {
  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
          {children}
        </span>
      </div>
    </li>
  );
}

function CustomLink({
  children,
  to,
}: Readonly<PropsWithChildren<{ to: string }>>) {
  const navigate = useNavigate();

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <span
          onClick={() => navigate(to)}
          className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 cursor-pointer"
        >
          {children}
        </span>
      </div>
    </li>
  );
}

function DocumentSelection() {
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [documentFormLoader, setDocumentFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { getProjectAllDocumentsRes, selectedDocument } = useAppSelector(
    (state) => state.projects
  );
  const dispatch = useAppDispatch();

  const onSelectingDocument = (value: any) => {
    dispatch(setSelectedDocument(value));
  };

  const creatingDocument = (body: any) => {
    const fetchData = async () => {
      setDocumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createProjectDocument({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setOpenDocumentModal(false);
          setDocumentFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDocumentFormLoader(false);
      }
    };
    fetchData();
  };

  if (!getProjectAllDocumentsRes || getProjectAllDocumentsRes.length === 0)
    return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={getProjectAllDocumentsRes}
          selected={selectedDocument}
          setSelected={onSelectingDocument}
          onAddingDocument={() => setOpenDocumentModal(true)}
          btnText="Add Document"
          buttonClassName="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700"
          className="flex"
          dropdownClassName="top-full"
        />
      </div>
      <CustomModal
        open={openDocumentModal}
        onCloseModal={() => setOpenDocumentModal(false)}
        title="Add Document"
        size="max-w-lg"
      >
        <DocumentForm
          onCloseModal={() => setOpenDocumentModal(false)}
          loading={documentFormLoader}
          onSubmitData={creatingDocument}
          editData={null}
        />
      </CustomModal>
    </li>
  );
}

function DiagramSelection() {
  const [openDiagramModal, setOpenDiagramModal] = useState(false);
  const [diagramFormLoader, setDiagramFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { getDiagramsListRes, selectedDiagram } = useAppSelector(
    (state) => state.diagrams
  );
  const dispatch = useAppDispatch();

  const onSelectingDiagram = (value: any) => {
    dispatch(setSelectedDiagram(value));
  };

  const creatingDiagram = (body: any) => {
    const fetchData = async () => {
      setDiagramFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createDiagram({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setOpenDiagramModal(false);
          setDiagramFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDiagramFormLoader(false);
      }
    };
    fetchData();
  };

  if (!getDiagramsListRes || getDiagramsListRes.length === 0) return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={getDiagramsListRes}
          selected={selectedDiagram}
          setSelected={onSelectingDiagram}
          onAddingDocument={() => setOpenDiagramModal(true)}
          btnText="Add Diagram"
          buttonClassName="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700"
          className="flex"
          dropdownClassName="top-full"
        />
      </div>
      <CustomModal
        open={openDiagramModal}
        onCloseModal={() => setOpenDiagramModal(false)}
        title="Add Diagram"
        size="max-w-lg"
      >
        <DiagramForm
          onCloseModal={() => setOpenDiagramModal(false)}
          loading={diagramFormLoader}
          onSubmitData={creatingDiagram}
          editData={null}
        />
      </CustomModal>
    </li>
  );
}

function TemplateDocumentsSelection() {
  const [documentsList, setDocumentsList] = useState([]);

  const { getPublicTemplateByIdRes, templateByIdRes, templateSelectedDocument } = useAppSelector(
    (state) => state.templates
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const firstPath = getFirstPathFromUrl(window.location.pathname);

  useEffect(() => {
    if (firstPath === "template") {
      if (templateByIdRes && templateByIdRes?.document_templates.length > 0) {
        setDocumentsList(templateByIdRes?.document_templates);
      }
    }

    if (firstPath === "community-template") {
      if (
        getPublicTemplateByIdRes &&
        getPublicTemplateByIdRes?.document_templates.length > 0
      ) {
        setDocumentsList(getPublicTemplateByIdRes?.document_templates);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPublicTemplateByIdRes, templateByIdRes]);

  const onSelectingDocument = (value: any) => {
    const pathName = window.location.pathname;
    const newPathname = pathName.substring(0, pathName.lastIndexOf("/"));
    navigate(`${newPathname}/${value?.id}`);
    dispatch(setTemplateSelectedDocument(value));
  };

  if (!documentsList || documentsList.length === 0) return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={documentsList}
          selected={templateSelectedDocument}
          setSelected={onSelectingDocument}
          onAddingDocument={null}
          btnText=""
        />
      </div>
    </li>
  );
}

function TChartSelection() {
  const [openTChart, setOpenTChart] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { tBarsList, selectedTChart } = useAppSelector((state) => state.tbars);
  const dispatch = useAppDispatch();

  const creatingTbar = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createTbar({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setOpenTChart(false);
          setFormLoader(false);

          await dispatch(
            getTbarsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitTChart = async (body: any) => {
    creatingTbar(body);
  };

  const onSelectingTChart = (value: any) => {
    dispatch(setSelectedTChart(value));
  };

  if (!tBarsList || tBarsList.length === 0) return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={tBarsList.map(({ tbar_analysis }) => ({
            ...tbar_analysis,
            title: tbar_analysis.details.TBarTitle,
          }))}
          selected={
            selectedTChart
              ? { ...selectedTChart, title: selectedTChart.details.TBarTitle }
              : null
          }
          setSelected={onSelectingTChart}
          onAddingDocument={() => setOpenTChart(true)}
          btnText="Add T-Chart"
          buttonClassName="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700"
          className="flex"
          dropdownClassName="top-full"
        />
      </div>
      <CustomDrawer
        open={openTChart}
        onCloseModal={() => setOpenTChart(false)}
        title={
          <div className="flex items-center">
            <span className="mr-2">{tChartIcon}</span>
            <span>T-Chart</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description="Create new T-Chart, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <TBarForm
          onCloseModal={() => setOpenTChart(false)}
          editData={null}
          onSubmitForm={onSubmitTChart}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </li>
  );
}

function ProsConsSelection() {
  const [openProsCons, setProsConsOpen] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { prosConsList, selectedProsCons } = useAppSelector(
    (state) => state.prosCons
  );
  const dispatch = useAppDispatch();

  const creatingProsCons = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProsCons({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setProsConsOpen(false);
          setFormLoader(false);

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProsCons = async (body: any) => {
    creatingProsCons(body);
  };

  const onSelectingProsCons = (value: any) => {
    dispatch(setSelectedProsCons(value));
  };

  if (!prosConsList || prosConsList.length === 0) return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={prosConsList}
          selected={selectedProsCons}
          setSelected={onSelectingProsCons}
          onAddingDocument={() => setProsConsOpen(true)}
          btnText="Add Pros & Cons"
          buttonClassName="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700"
          className="flex"
          dropdownClassName="top-full"
        />
      </div>
      <CustomDrawer
        open={openProsCons}
        onCloseModal={() => setProsConsOpen(false)}
        title="Pros-Cons"
        description="Create new Pros-Cons, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <ProConCreationForm
          onCloseModal={() => setProsConsOpen(false)}
          editData={null}
          onSubmitForm={onSubmitProsCons}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </li>
  );
}

function DecisionMatrixSelection() {
  const [openDecisionMatrix, setOpenDecisionMatrix] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { decisionMatrixList, selectedDecisionMatrix } = useAppSelector(
    (state) => state.decisionMatrixs
  );
  const dispatch = useAppDispatch();

  const creatingDecisionMatrix = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createDecisionMatrix({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setOpenDecisionMatrix(false);
          setFormLoader(false);

          await dispatch(
            getDecisionMatrixList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitDecisionMatrix = async (body: any) => {
    creatingDecisionMatrix(body);
  };

  const onSelectingDecisionMatrix = (value: any) => {
    dispatch(setSelectedDecisionMatrix(value));
  };

  if (!decisionMatrixList || decisionMatrixList.length === 0) return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={decisionMatrixList}
          selected={selectedDecisionMatrix}
          setSelected={onSelectingDecisionMatrix}
          onAddingDocument={() => setOpenDecisionMatrix(true)}
          btnText="Add Decision Matrix"
          buttonClassName="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700"
          className="flex"
          dropdownClassName="top-full"
        />
      </div>
      <CustomDrawer
        open={openDecisionMatrix}
        onCloseModal={() => setOpenDecisionMatrix(false)}
        title="Decision Matrix"
        description="Create new Decision Matrix, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <CreationDecisionMatrixForm
          onCloseModal={() => setOpenDecisionMatrix(false)}
          editData={null}
          onSubmitForm={onSubmitDecisionMatrix}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </li>
  );
}

function SWOTSelection() {
  const [openSWOT, setOpenSWOT] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { swotsList, selectedSwot } = useAppSelector((state) => state.swot);
  const dispatch = useAppDispatch();

  const onSelectingSWOT = (value: any) => {
    dispatch(setSelectedSwot(value));
  };

  const creatingSwot = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createSwot({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setOpenSWOT(false);
          setFormLoader(false);

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitSWOT = async (body: any) => {
    creatingSwot(body);
  };

  if (!swotsList || swotsList.length === 0) return null;

  return (
    <li>
      <div className="flex items-center">
        <Divider />
        <DropdownSelectionOption
          optionsList={swotsList}
          selected={selectedSwot}
          setSelected={onSelectingSWOT}
          onAddingDocument={() => setOpenSWOT(true)}
          btnText="Add SWOT"
          buttonClassName="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700"
          className="flex"
          dropdownClassName="top-full"
        />
      </div>
      <CustomDrawer
        open={openSWOT}
        onCloseModal={() => setOpenSWOT(false)}
        title="SWOT Analysis"
        description="Create new SWOT, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <CreationSWOTForm
          onCloseModal={() => setOpenSWOT(false)}
          editData={null}
          onSubmitForm={onSubmitSWOT}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </li>
  );
}

// Add this new component definition
function BreadcrumbNav({
  children,
  className = "",
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) {
  return (
    <nav className={`flex ${className}`} aria-label="Breadcrumb" {...props}>
      <ol className="flex items-center space-x-4">{children}</ol>
    </nav>
  );
}

export default function MainHeader({
  setSidebarOpen,
  collapseSidebar,
}: MainHeaderProps) {
  const [formLoader, setFormLoader] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [openAiAssistant, setOpenAiAssistant] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const { projectsByIdRes, multiSelectedOption } = useAppSelector(
    (state) => state.projects
  );
  const { communityPageTab, getUserAiAssistantRes, getPublicAiAssistantRes } = useAppSelector(
    (state) => state.userAiAssistants
  );

  const {
    templateByIdRes,
    getPublicTemplateByIdRes,
    templateSelectedDocument,
  } = useAppSelector((state) => state.templates);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProfileData(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              gettingProjectsList();
              navigate(`/projects`);
            }
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProject = async (body: any) => {
    body.user_id = user?.sub;

    creatingProject(body);
  };

  const gettingProjectsList = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectsList(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const creatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          await dispatch(createUserAiAssistant({ body, accessToken }))
            .then(() => {
              setOpenAiAssistant(false);
              setFormLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const onSubmitAiAssistant = async (body: any) => {
    creatingAIAssistant(body);
  };

  return (
    <div id="main_header">
      <div
        className={`w-full fixed top-0 z-40 flex h-12 shrink-0 items-center gap-x-4 px-5`}
      >
        <div className="flex mr-3">
          <img
            className="h-[30px] cursor-pointer"
            src={mainLogo}
            alt="Solution Pilot"
            onClick={() => navigate("/")}
          />
        </div>

        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div className="flex items-center justify-between w-full">
          <div className={`${collapseSidebar ? "ml-[10px]" : "-ml-[7px]"}`}>
            {pathname === "/home" && (
              <BreadcrumbNav>
                <HomeLink disabled />
                <Text>Home</Text>
              </BreadcrumbNav>
            )}

            {/** Projects Pages **/}
            {pathname === "/projects" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Projects</Text>
              </BreadcrumbNav>
            )}

            {pathname === `/projects/${projectsByIdRes?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <CustomLink to="/projects">Projects</CustomLink>
                <Text>{projectsByIdRes?.title}</Text>

                {multiSelectedOption === 1 && (
                  <>
                    <Text>Documents</Text>
                    <DocumentSelection />
                  </>
                )}

                {multiSelectedOption === 2 && (
                  <>
                    <Text>Diagrams</Text>
                    <DiagramSelection />
                  </>
                )}

                {multiSelectedOption === 4 && (
                  <>
                    <Text>T-Chart Analysis</Text>
                    <TChartSelection />
                  </>
                )}

                {multiSelectedOption === 5 && (
                  <>
                    <Text>Pros & Cons</Text>
                    <ProsConsSelection />
                  </>
                )}

                {multiSelectedOption === 6 && (
                  <>
                    <Text>Decision Matrix</Text>
                    <DecisionMatrixSelection />
                  </>
                )}

                {multiSelectedOption === 7 && (
                  <>
                    <Text>SWOT Analysis</Text>
                    <SWOTSelection />
                  </>
                )}

                {multiSelectedOption === 8 && <Text>Project Settings</Text>}
              </BreadcrumbNav>
            )}

            {pathname === "/playground" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Ai Prompts</Text>
                <Text>Playground</Text>
              </BreadcrumbNav>
            )}

            {pathname === "/ai-assistants" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Ai Prompts</Text>
                <Text>Prompt Book</Text>
              </BreadcrumbNav>
            )}

            {pathname === `/ai-assistant/${getUserAiAssistantRes?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Ai Prompts</Text>
                <CustomLink to="/ai-assistants">Prompt Book</CustomLink>
                <Text>{getUserAiAssistantRes?.title}</Text>
              </BreadcrumbNav>
            )}

            {/** Community Designs Main **/}
            {pathname === "/community-templates" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Community</Text>
                <Text>Design & Documents</Text>
              </BreadcrumbNav>
            )}

            {/** Community Design Detail **/}
            {pathname ===
              `/community-template/${getPublicTemplateByIdRes?.project_template?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Community</Text>
                <Text>Design & Documents</Text>
                <Text>{getPublicTemplateByIdRes?.project_template?.title}</Text>
              </BreadcrumbNav>
            )}

            {/** Community Design Single Document Detail **/}
            {pathname ===
              `/community-template/${templateSelectedDocument?.project_template_id}/document/${templateSelectedDocument?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Community</Text>
                <Text>Design & Documents</Text>
                <Text>{getPublicTemplateByIdRes?.project_template?.title}</Text>
                <Text>Document</Text>
                <TemplateDocumentsSelection />
              </BreadcrumbNav>
            )}

            {pathname === "/community-ai-assistants" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Community</Text>
                <Text>Ai Prompts</Text>
              </BreadcrumbNav>
            )}

            {pathname ===
              `/community-ai-assistant/${getPublicAiAssistantRes?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Community</Text>
                <Text>Ai Prompts</Text>
                <Text>{getPublicAiAssistantRes?.title}</Text>
              </BreadcrumbNav>
            )}

            {pathname === "/templates" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Content Templates</Text>
              </BreadcrumbNav>
            )}

            {pathname ===
              `/template/${templateByIdRes?.project_template?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <CustomLink to="/templates">Content Templates</CustomLink>
                <Text>{templateByIdRes?.project_template?.title}</Text>
              </BreadcrumbNav>
            )}

            {pathname ===
              `/template/${templateSelectedDocument?.project_template_id}/document/${templateSelectedDocument?.id}` && (
              <BreadcrumbNav>
                <HomeLink />
                <CustomLink to="/templates">Content Templates</CustomLink>
                <Text>{templateByIdRes?.project_template?.title}</Text>
                <Text>Document</Text>
                <Text>{templateSelectedDocument?.title}</Text>
              </BreadcrumbNav>
            )}

            {pathname === "/document-components" && (
              <BreadcrumbNav>
                <HomeLink />
                <Text>Document Components</Text>
              </BreadcrumbNav>
            )}

            {pathname === "/community" && (
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {communityPageTabsList.map((tab: any) => (
                  <span
                    key={tab.id}
                    onClick={() => dispatch(setCommunityPageTabs(tab.id))}
                    className={classNames(
                      tab.id === communityPageTab
                        ? "text-amber-500 border-amber-500"
                        : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-2 px-2 text-xs font-medium cursor-pointer"
                    )}
                  >
                    {tab.name}
                  </span>
                ))}
              </nav>
            )}
          </div>

          <div className="flex items-center gap-x-2">
            <LoginUserInfo collapseSidebar={collapseSidebar} />
          </div>
        </div>
      </div>

      <CustomDrawer
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
        title="Create Project"
        description="Create new Project, provide Title, Status and Category. Title is mandatory field."
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={null}
          onSubmitForm={onSubmitProject}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomDrawer
        open={openAiAssistant}
        onCloseModal={() => setOpenAiAssistant(false)}
        title="Update AI Assistant"
        description="Create new AI Assistant, provide all the data during create your Assistant."
      >
        <CreateAiAisstantForm
          onCloseModal={() => setOpenAiAssistant(false)}
          editData={null}
          onSubmitForm={onSubmitAiAssistant}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </div>
  );
}
