import React, { SelectHTMLAttributes } from "react";

interface CommonSelectProps {
  options: any;
  id: string;
  rest: any
}

type SelectFieldProps = CommonSelectProps &
  SelectHTMLAttributes<HTMLSelectElement>;

const SelectField: React.FC<SelectFieldProps> = ({ options, id, ...rest }) => {
  return (
    <select
      id={id}
      className="block capitalize w-full bg-white rounded-md border-0 py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-xs sm:leading-6"
      {...rest}
    >
      {options.map((data: { value: string }, index: number) => (
        <option key={index} value={data.value} className="capitalize">
          {data.value}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
