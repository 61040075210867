import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks";

interface MenuSubItemsListProps {
  classNames: Function;
  collapseSidebar: boolean;
  data: any;
}

export default function MenuSubItemsList({
  classNames,
  collapseSidebar,
  data,
}: MenuSubItemsListProps) {
  const { currentPage } = useAppSelector((state) => state.profile);

  return (
      <li key={data.name} className={collapseSidebar ? "ml-0" : "-ml-[14px]"}>
          <Link
            to={data?.href}
            className={classNames(
              data.href === currentPage?.href
                ? "bg-white text-amber-500"
                : "text-zinc-900 hover:text-amber-500 hover:bg-zinc-100 border-transparent opacity-90 hover:opacity-100",
              `flex gap-x-3 rounded-md p-2 text-xs leading-5 font-medium cursor-pointer items-center ${
                collapseSidebar ? "items-center justify-start" : ""
              }`
            )}
          >
              <span
                className={classNames(
                  data.href === currentPage?.href
                    ? "text-amber-500"
                    : "hover:text-amber-500"
                )}
              >
                  <data.icon className="w-[17px] h-[17px]" />
              </span>
              <span>{data.name}</span>{" "}
          </Link>
      </li>
  );
}
