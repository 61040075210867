export function getLastIdFromUrl(url: string) {
  const segments = url.split("/");
  const lastSegment = segments[segments.length - 1];
  return lastSegment;
}

export function humanReadDate(created_at: string) {
  // Create a Date object
  const date = new Date(created_at);

  // Options for formatting the date
  const options: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

function timeAgo(date: any) {
  const now: any = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return seconds <= 1 ? "just now" : `${seconds} seconds ago`;
  }
}

function formatDate(date: any) {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

export function timeAgoOrFormattedDate(stringDate: any) {
  const date: any = new Date(stringDate);
  const now: any = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days <= 7) {
    // Use timeAgo for dates within a week
    return timeAgo(date);
  } else {
    // Use a specific date format for dates older than a week
    return formatDate(date);
  }
}

function cleanString(inputString: string) {
  // Remove '#' and replace newline characters with spaces
  let cleanedString = inputString.replace(/#/g, "").replace(/\n/g, " ");

  // Remove HTML tags
  cleanedString = cleanedString.replace(/<[^>]*>/g, "");

  // Remove '&nbsp;'
  cleanedString = cleanedString.replace(/&nbsp;/g, "");

  return cleanedString.trim();
}

export function countWords(inputString: string) {
  let cleanedString = cleanString(inputString);

  if (cleanedString === "") {
    return 0;
  }

  let words = cleanedString.split(/\s+/);
  return words.length;
}

export function countCharacters(inputString: string) {
  let cleanedString = cleanString(inputString);

  return cleanedString.length;
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const parseQueryStringToObject = (url: string) => {
  const urlObj = new URL(url, window.location.origin);
  const urlSearchParams = urlObj.searchParams;
  return Object.fromEntries(urlSearchParams.entries());
};

export function getFirstPathFromUrl(url: string) {
  const segments = url.split("/");
  const firstSegment = segments[1];
  return firstSegment;
}

export function getPathnameInSplitArray(url: string) {
  const segments = url.split("/");
  return segments;
}

export function getQueryParams(url: string): Record<string, string> {
  // Create a URL object
  const urlObj = new URL(url);

  // Get the query string parameters
  const params = new URLSearchParams(urlObj.search);

  // Convert the parameters into an object
  const queryParams: Record<string, string> = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}

export const gradientText =
  "inline-block bg-gradient-to-r from-amber-400 to-amber-600 bg-clip-text text-transparent";

export const categoriesList = [
  { value: "Cloud Architecture" },
  { value: "Software Architecture" },
  { value: "Containerization" },
  { value: "DevOps & CI/CD" },
  { value: "Enterprise Business Design" },
  { value: "Security Model" },
];

export const initialNodes = [
  {
    id: "react-component",
    type: "input",
    data: { label: "1. React Component" },
    position: { x: 150, y: 25 },
  },
  {
    id: "action-creators",
    data: { label: "2. Action Creators" },
    position: { x: 300, y: 125 },
  },
  {
    id: "redux-store",
    data: { label: "3. Redux Store" },
    position: { x: 150, y: 225 },
  },
  {
    id: "reducers",
    data: { label: "4. Reducers" },
    position: { x: 300, y: 325 },
  },
  {
    id: "state",
    type: "output",
    data: { label: "5. State" },
    position: { x: 150, y: 425 },
  },
  {
    id: "result",
    type: "output",
    data: { label: "6. Result" },
    position: { x: 150, y: 425 },
  },
];

export const initialEdges = [
  {
    id: "e1",
    source: "react-component",
    target: "action-creators",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "dispatch action",
  },
  {
    id: "e2",
    source: "action-creators",
    target: "redux-store",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "returns action",
  },
  {
    id: "e3",
    source: "redux-store",
    target: "reducers",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "current state & action",
  },
  {
    id: "e4",
    source: "reducers",
    target: "redux-store",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "new state",
  },
  {
    id: "e5",
    source: "redux-store",
    target: "state",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "updates state",
  },
  {
    id: "e6",
    source: "state",
    target: "react-component",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "passes state as props",
  },
  {
    id: "e7",
    source: "state",
    target: "react-component",
    animated: true,
    arrowHeadType: "arrowclosed",
    label: "passes state as props",
  },
];

export function formatLatestDate(dateString: string) {
  const date = new Date(dateString);

  // Array for weekday and month names
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract day, month, and year
  const dayName = daysOfWeek[date.getUTCDay()];
  const monthName = monthsOfYear[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Return formatted string
  return `${dayName}, ${monthName} ${day}, ${year}`;
}